/*------------------------------
 * Copyright 2016 Pixelized
 * http://www.pixelized.cz
 *
 * umarket theme v1.1
------------------------------*/

/*------------------------------
	FONT AWESOME CORE
------------------------------*/

header.navbar .navbar-nav > li > a.dropdown-toggle:after,
header.navbar .dropdown-menu > li > a:before,
.widget-categories .widget-body > ul > li > a:before,
.checkbox input[type=checkbox]:checked + label:before,
.sidebar .widget h3 a:after,
.sidebar .nav-pills > li > a:after,
.panel .panel-heading h4.panel-title a:before,
.widget-recent-comments ul li:before {
	display:inline-block;
    font:normal normal normal 14px/1 FontAwesome;
    font-size:inherit;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
}

/*------------------------------
	MAIN
------------------------------*/

html, body, #page-wrapper {
    height:100%;
}

body {
	background-image:url(../images/pattern.png);
	font-family:'Source Sans Pro', Verdana, Geneva, sans-serif;
}

a:focus,
a:hover {
	color:#555555;
}

a,
a:focus,
a:hover,
button,
button:focus,
button:hover,
button:active {
	outline:0 none;
	text-decoration:none;
}

p {
	margin:0 0 20px;
	color:#444444;
}

li {
	color:#444444;
}


h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
	font-family:'Source Sans Pro', Verdana, Geneva, sans-serif
}

.row-no-padding {
	margin-left:0!important;
    margin-right:0!important;
}

.row-no-padding > [class*="col-"] {
    padding-left:0!important;
    padding-right:0!important;
}

#map-canvas {
    height:500px;
    margin:0;
    padding:0;
}

/* BOXED LAYOUT */

.page-boxed #page-wrapper {
	width:auto;
	margin:0 auto;
}

.page-boxed {
	background-color:#DDDDDD;
	background-image:none;
}

@media (min-width: 768px) {
 	.page-boxed #page-wrapper {
    	width:750px;
  	}
	
	.page-boxed .navbar-fixed-top {
		width:750px;
		margin:0 auto;
	}
}

@media (min-width: 992px) {
 	.page-boxed #page-wrapper,
	.page-boxed .navbar-fixed-top {
    	width:971px;
  	}
}

@media (min-width: 1200px) {
	.page-boxed #page-wrapper,
	.page-boxed .navbar-fixed-top {
    	width:1171px;
  	}
}

/*------------------------------
	TYPOGRAPHY
------------------------------*/

.text-muted {
	color:#999999;
}

/*------------------------------
	LISTS
------------------------------*/

.list-icons li i {
	margin-right:10px;
	color:#777777;
}

/*------------------------------
	BUTTONS
------------------------------*/

.btn {
	border-radius:0;
	text-transform:uppercase;
	font-weight:600;
}

.btn-lg {
	font-size:16px;
	padding:8px 14px;
}

.input-group-lg > .input-group-btn > .btn {
	border-radius:0;
	font-size:16px;
}

.btn-default {
	background-color:transparent;
	border:2px solid #D0D0D0;
	color:#CCCCCC;
}

.btn-default:hover,
.btn-default:focus {
	background-color:transparent;
	border-color:#B0B0B0;
	color:#AAAAAA;
}

.btn-default.active, 
.btn-default:active,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default:active:hover,
.btn-default:active:focus {
	background-color:transparent;
}

.btn-primary {
	border:2px solid rgba(0,0,0,0.1);
}

.btn-primary:hover,
.btn-primary:focus {
	background-color:#888888;
	border:2px solid rgba(0,0,0,0.1);
}

.btn-primary.active, 
.btn-primary:active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary:active:hover,
.btn-primary:active:focus {
	background-color:#555555;
	border:2px solid rgba(0,0,0,0.1);
}

.btn-inverse {
	background-color:#666666;
	border:2px solid #555555;
	color:#FFFFFF;
}

.btn-inverse:hover,
.btn-inverse:focus {
	color:#DDDDDD;
}

.btn.active,
.btn:active {
	box-shadow:none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
	outline:0 none;
	outline-offset:0;
}

.btn-info,
.btn-danger,
.btn-warning,
.btn-success {
	border:2px solid rgba(0,0,0,0.1);
}

/*------------------------------
	FORM
------------------------------*/

label {
	color:#888888;
}

form.form-inline label {
	margin:0 10px 0 0;
}

span.required {
    color:#E74C3C!important;
    margin-left:5px!important;
    font-weight:bold!important;
    font-size:14px!important;
    font-family:Arial, Helvetica, sans-serif!important;
}

.form-control {
	border-radius:0;
	height:36px;
	box-shadow:none;
	border:2px solid #D0D0D0;
	color:#999999;
	font-weight:bold;
	transition:color 0.2s ease-in, border-color 0.2s ease-in;
}

.form-control:hover {
	border-color:#B0B0B0;
	color:#AAAAAA;
}

.input-group-addon {
	border-radius:0;
	border:2px solid #D0D0D0;
	background-color:#F3F3F3;
}

.form-control:focus {
	outline:none;
	box-shadow:none;
}

select.form-control {
	color:#CCCCCC;
	background-image:url(../images/angle-down.png);
	background-repeat:no-repeat;
	-webkit-appearance:none;
	-moz-appearance:none;
	background-position:right center;
	background-size:contain;
	padding-right:25px;
}

select.form-control::-ms-expand {
	/* IE 8 */
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	/* IE 5-7 */
	filter:alpha(opacity=0);
	/* Other IE */
	opacity:0;
}

.input-group-lg > .form-control {
	border-radius:0;
	font-size:16px;
}

textarea {
	resize:vertical;
}

/* CHECKBOX */

.checkbox input[type=checkbox],
.radio input[type=radio] {  
    display:none;  
}

.checkbox > label,
.radio > label {  
    padding-left:25px;
	font-weight:600;
	color:#777777;
	font-size:15px;
} 

.checkbox > label:before,
.radio > label:before {  
	background-color:#FFFFFF;
    content:"";  
    width:18px;  
    height:18px;  
    position:absolute;  
    left:0;   
   	border:2px solid #D0D0D0; 
	top:2px;
}  

.checkbox > input[type=checkbox]:checked + label:before,
.radio > input[type=radio]:checked + label:before {
	color:rgba(0,0,0,0.2);
	border-color:rgba(0,0,0,0.1);
    content:"\f00d"; 
	padding-top:1px;  
    text-align:center;
	font-size:13px;  
}

.checkbox.disabled > input[type=checkbox]:disabled + label:before,
.radio.disabled > input[type=radio]:disabled + label:before {
	background-color:#F0F0F0;
}

/* RADIO */

.radio > label:before {  
    border-radius:50%;
}  

.radio > input[type=radio]:checked + label:before {
    content:"";   
}

/*------------------------------
	TABLE
------------------------------*/

.table-bordered {
	border:2px solid #EBEBEB;
}

.table > thead > tr > th,
.table > tbody > tr > th {
	color:#FFFFFF;
	border-width:2px;
	border-color:#EBEBEB;
	border-top-width:0;
}

.table > thead > tr > td,
.table > tbody > tr > td {
	border-width:2px;
	vertical-align:middle;
	font-weight:600;
	color:#888888;
	border-color:#EBEBEB;
}

.table td span.grey {
	font-weight:400;
	color:#AAAAAA;
    font-style:italic;
}

/*------------------------------
	ACCORDION
------------------------------*/

.panel-group .panel +.panel {
	margin-top:10px;
}

.panel-group .panel {
	border-radius:0;
	border:0;
	box-shadow:0 1px 2px 0 rgba(0,0,0,0.2);
}

.panel-heading {
	border-radius:0;
}

.panel .panel-heading h4.panel-title {
	font-size:18px;
	font-weight:600;
	margin:0 0 0 20px;
	position:relative;
}

.panel .panel-heading h4.panel-title a:before {
    content:"\f056";
    margin-top:-9px;
    position:absolute;
    left:-25px;
    top:50%;
}

.panel .panel-heading h4.panel-title a.collapsed:before {
    content:"\f055";
}

.panel-group .panel .panel-collapse > .panel-body {
	border:0;
	background-color:#F9F9F9;
}

.panel-primary > .panel-heading {
	border:2px solid rgba(0,0,0,0.1);
}

.panel-default > .panel-heading {
	background-color:#AAAAAA;
	border:2px solid rgba(0,0,0,0.1);
}

.panel-primary > .panel-heading a,
.panel-default > .panel-heading a {
	color:#FFFFFF;
}

/*------------------------------
	ALERT
------------------------------*/

.alert {
	border-radius:0;
	border-width:2px;
	padding:20px;
}

.alert-dismissable, .alert-dismissible {
    padding-right:40px;
}

.alert .close i {
	font-size:16px;
}

.alert-danger,
.alert-info,
.alert-success,
.alert-warning {
	border-color:rgba(0,0,0,0.03);
}

.alert-danger {
    color:#BA5B68;
    background-color:#F5E1E1;
}

.alert-info {
    color:#4B90AE;
    background-color:#E1F5F4;
}

.alert-success {
	color:#4BAE4F;
    background-color:#E1F5E3;
}

.alert-warning {
    color:#AEA64B;
    background-color:#FAFAD2;
}

/*------------------------------
	LABEL
------------------------------*/

.label {
	display:inline-block;
	margin-bottom:3px;
	font-size:14px;
	padding:4px 6px;
}

.label-lg {
	font-size:14px;
	padding:5px 7px;
}

.label-danger {
    background-color:#BA5B68;
}

.label-info {
    background-color:#4B90AE;
}

.label-success {
    background-color:#4BAE4F;
}

.label-warning {
    background-color:#AEA64B;
}

/* TAGS */

.tags span.label {
	padding:6px 8px;
    background-color:#EAEAEA;
    margin-right:3px;
	margin-bottom:6px;
	border-radius:0;
}

.tags span.label a {
	color:#9B9B9B;
}

.tags span.label i {
    margin-right:5px;
	color:#D2D2D2;
}

.tags span.label:hover a,
.tags span.label:hover i {
	color:#FFFFFF;
}

/*------------------------------
	TABS
------------------------------*/

.tabs .nav-tabs {
	border-width:2px;
}

.tabs .nav-tabs > li {
	margin-bottom:0;
	background-color:#FFFFFF;
	margin-right:5px;
	padding:0;
}

.tabs .nav-tabs > li > a {
	border-radius:0;
	margin-right:0;
	border:0;
    font-size:16px;
    font-weight: bold;
    color: #777777;
	padding:14px 20px;
}

.tabs .nav-tabs > li.active > a, 
.tabs .nav-tabs > li.active > a:focus, 
.tabs .nav-tabs > li.active > a:hover {
	color:#FFFFFF;
	border:0;
}

.tabs .tab-content {
	padding-top:20px;
}

.tabs .tab-content h4, 
.tabs .tab-content h5 {
	font-weight:bold;
	color:#777777;
	margin:0 0 5px;
}

.tabs .tab-content h4 {
	font-size:21px;
}

.tabs .tab-content h5 {
	font-size:17px;
}

.tabs .tab-content ol, 
.tabs .tab-content ul {
	margin-bottom:20px;
}

.tabs .tab-content p,
.tabs .tab-content ul li {
	color:#777777;
	font-size:16px;
}

/*------------------------------
	BREADCRUMB
------------------------------*/

.breadcrumb-wrapper {
	background-color:#FFFFFF;
	padding:10px 0;
}

.breadcrumb-wrapper h2 {
	margin:5px 0 0;
	font-size:20px;
	font-weight:bold;
	color:#777777;
	letter-spacing:-1px;
}

.breadcrumb-wrapper p {
	margin:0;
	font-size:32px;
	font-weight:100;
	color:#333333;
}

.breadcrumb {
	background-color:transparent;
	margin:0;
	text-align:right;
	padding:25px 0;
}

.breadcrumb > li > a {
	font-weight:bold;
	color:#777777;
}

.breadcrumb > .active {
	color:#999999;
}

/*------------------------------
	PAGINATION
------------------------------*/

.pagination-wrapper {
	padding:15px 10px 5px;
	text-align:center;
}

.pagination {
	margin:0;
	border-radius:0;
}

.pagination > li > a {
	margin:0 5px 5px;
	border:2px solid #D0D0D0;
    color:#CCCCCC;
	font-weight:bold;
	font-size:16px;
	width:38px;
	height:38px;
	text-align:center;
	padding:6px 0 0;
}

.pagination > li:first-child > a,
.pagination > li:last-child > a {
	border-radius:0;
}

.pagination > li > a:hover,
.pagination > li > a:focus {
	background-color:#FFFFFF;
	color:#AAAAAA;
	border-color:#B0B0B0;
}

.pagination > li.active > a,
.pagination > li.active > a:hover,
.pagination > li.active > a:focus {
	background-color:#FFFFFF;
}

.pagination-lg > li > a {
	font-size:22px;
	width:46px;
	height:46px;
	padding:7px 0 0;
}

.pagination-sm > li > a {
	font-size:12px;
	width:30px;
	height:30px;
	padding:5px 0 0;
}

/*------------------------------
	EMBED RESPONSIVE
------------------------------*/

.embed-responsive-audio {
    padding-bottom:0;
    height:166px;
}

/*------------------------------
	MODALS
------------------------------*/

.modal .modal-header {
    padding:0;
    border-bottom:0;
}
.modal{
	z-index:10001 !important;
}
.modal-backdrop{
	z-index:10000 !important;
}

.modal .modal-content {
	border-radius:0;
}

.modal .modal-body {
	padding-top:0;
}

.modal .modal-header .close {
	background-color:#555555;
	border-radius:50%;
	text-shadow:none;
	opacity:1;
	color:#F0F0F0;
	width:32px;
	height:32px;
	margin-top:-13px;
	margin-right:-13px;
	text-align:center;
	font-size:18px;
}

/*------------------------------
	MODAL - PRODUCT QUICKVIEW
------------------------------*/

.modal-quickview article.product-item.product-single {
	box-shadow:none;
	margin-bottom:0;
	padding-right:0;
}

.modal-quickview article.product-item.product-single h3 {
	color:#888888;
	margin-top:0;
}

.modal-quickview article.product-item.product-single .product-labels {
	padding-top:0;
}

/*------------------------------
	MODAL - ADD REVIEW
------------------------------*/

.modal-add-review .modal-header {
	padding:15px;
}

.modal-add-review .modal-header .close {
	margin-top:-28px;
	margin-right:-28px;
}

/*------------------------------
	MODAL - ADVERTISING
------------------------------*/

#modalAdvertising .modal-header {
	background-image:url(../images/pattern.png);
}

#modalAdvertising .modal-body {
	padding:15px 30px 0;
	background-image:url(../images/pattern.png);
}

#modalAdvertising .modal-body h3 {
	font-weight:900;
    color:#666666;
    letter-spacing:-1px;
    margin-top:0;
    font-size:32px;
}

#modalAdvertising .modal-body p {
    color:#AAAAAA;
    font-weight:600;
    font-size:16px;
}

#modalAdvertising .modal-body .checkbox {
    margin:30px 0;
}

/*------------------------------
	MODAL - SIZES
------------------------------*/

@media (min-width: 768px) {
	.modal-quickview .modal-dialog {
		width:600px;
		margin:30px auto;
	}
	
	.modal-add-review .modal-dialog {
		width:600px;
		margin:30px auto;
	}
	
	#modalAdvertising .modal-dialog {
		width:600px;
		margin:100px auto;
	}
}

@media (min-width: 992px) {
	.modal-quickview .modal-dialog {
		width:800px;
		margin:45px auto;
	}
	
	.modal-add-review .modal-dialog {
		width:650px;
		margin:40px auto;
	}
	
	#modalAdvertising .modal-dialog {
		margin:150px auto;
	}
}

@media (min-width: 1200px) {
	.modal-quickview .modal-dialog {
		width:900px;
		margin:60px auto;
	}
	
	.modal-add-review .modal-dialog {
		width:700px;
		margin:50px auto;
	}
	
	#modalAdvertising .modal-dialog {
		margin:200px auto;
	}
}

@media (min-width: 1600px) {
	.modal-quickview .modal-dialog {
		width:1000px;
		margin:90px auto;
	}
	
	.modal-add-review .modal-dialog {
		width:800px;
		margin:60px auto;
	}
	
	#modalAdvertising .modal-dialog {
		margin:300px auto;
	}
}

/*------------------------------
	OWL CAROUSEL
------------------------------*/

.owl-theme .owl-controls {
	margin:0;
}

/* OWL - NAV */

.owl-theme .owl-controls .owl-nav div {
	margin:0;
	width:40px;
	height:40px;
	padding:4px 0 0;
	text-align:center;
	border-radius:50%;
	font-size:23px;
	background-color:#666666;
	color:#FFFFFF;
	position:absolute;
	top:50%;
	margin-top:-20px;
}

.owl-theme .owl-controls .owl-nav div.owl-prev {
	left:-37px;
}

.owl-theme .owl-controls .owl-nav div.owl-next {
	right:-37px;
}

.owl-theme.nav-inside .owl-controls .owl-nav div {
	opacity:0.6;
	transition:all 0.2s ease-in;
}

.owl-theme.nav-inside .owl-controls .owl-nav div:hover {
	opacity:1;
}

.owl-theme.nav-inside .owl-controls .owl-nav div.owl-prev {
	left:20px;
}

.owl-theme.nav-inside .owl-controls .owl-nav div.owl-next {
	right:20px;
}

body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-prev {
	left:-80px;
}

body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-next {
	right:-80px;
}

/* OWL - PAGINATION */

.owl-theme .owl-controls .owl-dots {
	text-align:center;
}

.owl-theme .owl-controls .owl-dot {
    width:16px;
    height:16px;
    margin:10px 5px 5px;
    background-color:#F0F0F0;
	border:2px solid rgba(0,0,0,0.1);
	border-radius:50%;
	display:inline-block;
}

.owl-theme.nav-inside .owl-controls .owl-dots {
	position:absolute;
	right:20px;
	bottom:20px;
	border-radius:20px;
	background-color:rgba(0,0,0,0.2);
	padding:0 5px;
}

.owl-theme.nav-inside .owl-controls .owl-dot {
	border:0;
}

/*------------------------------
	SCROLL TOP
------------------------------*/

#scrolltop {
	position:fixed;
	right:15px;
	bottom:15px;
	width:40px;
	height:40px;
	text-align:center;
	font-size:23px;
	padding-top:3px;
	background-color:#555555;
	color:#FFFFFF;
	transition:all 0.3s ease-in;
	z-index:1040;
	cursor:pointer;
	transform:translateY(70px);
}

#scrolltop.in {
	transform:translateY(0);
}

/*------------------------------
	HEADER
------------------------------*/

.top-header {
	box-shadow:0 20px 20px -20px rgba(0,0,0,0.2) inset;
	background-color:#F9F9F9;
	height:40px;
	border-bottom:1px solid #EBEBEB;
}

.top-header .contacts {
	margin:0;
}

.top-header .contacts li,
.vertical-menu-bottom .contacts li {
	color:#888888;
	font-weight:600;
	margin-top:8px;
}

.top-header .contacts li i,
.vertical-menu-bottom .contacts li i {
	border:2px solid #E87169;
	border-radius:50%;
	padding:5px 0 0;
	text-align:center;
	width:26px;
	height:26px;
	margin-right:5px;
	font-size:13px;
}

.top-header .links,
.top-header .languages {
	display:inline-block;
	padding:10px 0 0 10px;
}

.top-header .links li {
	padding:0 8px;
}

.top-header .links a {
	color:#888888;
	position:relative;
}

.top-header .links a:after {
	width:4px;
	height:4px;
	position:absolute;
	border-radius:50%;
	right:-11px;
	top:50%;
	margin-top:-1px;
	content:"";
	background-color:#888888;
}

.top-header .links li:last-child a:after {
	display:none;
}

.top-header-dark {
	background-color:#393939;
	border-color:#2B2B2B;
}

.top-header-dark .contacts li,
.navbar-dark .vertical-menu-bottom .contacts li {
	color:#AAAAAA;
}

.top-header-dark .links a {
	color:#AAAAAA;
}

/* HEADER - NAVBAR */

header.navbar {
	margin:0;
}

header.navbar-default {
	background-color:#FFFFFF;
	height:100px;
	border-color:#EBEBEB;
	transition:height 0.3s ease-in;
}

header.navbar > .container {
	position:relative;
}

header.navbar .navbar-brand {
	padding:37px 15px;
	height:auto;
	font-size:50px;
	font-weight:600;
	transition:all 0.3s ease-in;
}

header.navbar .navbar-brand span {
	color:#666666;
	transition:color 0.3s ease-in;
}

header.navbar .navbar-brand:hover {
	color:#666666;
}

header.navbar .navbar-text {
	color:#999999;
	font-weight:300;
	font-size:16px;
}

/* NAVBAR NAV */

header.navbar .navbar-collapse {
	margin:25px 0;
	transition:all 0.3s ease-in;
}

header.navbar .navbar-nav > li > a {
	font-size:18px;
	padding:15px 15px;
	color:#777777;
}

header.navbar .navbar-nav > li > a i.fa-caret-down {
	font-size:14px;
	color:#CCCCCC;
	margin-left:3px;
}

header.navbar .navbar-nav > li > a span.label {
	position:absolute;
	top:-5px;
	right:10px;
}

header.navbar .navbar-nav > li > a.dropdown-toggle:after {
	content:"\f0d7";
	font-size:14px;
	color:#CCCCCC;
	margin-left:6px;
}

header.navbar .navbar-nav > li > a:hover,
header.navbar .navbar-nav > li > a:focus {
	background-color:transparent;
}

header.navbar .navbar-nav > li.dropdown.open > a {
	background-color:transparent;
}

.dropdown-menu {
	border:0;
	padding:0;
	border-radius:0;
	min-width:200px;
	box-shadow:0 1px 5px 0 rgba(0,0,0,0.15);
}

header.navbar .dropdown-menu {
	margin-top:25px;
	transition:margin-top 0.3s ease-in;
}

.dropdown-menu > li > a {
	color:#777777;
	padding:10px 15px;
	border-bottom:1px solid #EEEEEE;
	font-size:16px;
}

.dropdown-menu > li:last-child > a {
	border-bottom:0;
}

header.navbar .dropdown-menu > li > a:before {
	content:"\f0da";
	color:#CCCCCC;
	margin-right:8px;
	font-size:14px;
}

header.navbar .dropdown-menu > li > a:hover,
header.navbar .dropdown-menu > li > a:focus {
	background-color:#F9F9F9;
}

/* MEGAMENU */

header.navbar .navbar-nav > li.megamenu {
	position:static;
}

header.navbar .megamenu .dropdown-menu {
	left:15px;
	right:15px;
	margin-top:-19px;
	padding:10px 5px 0;
}

header.navbar .megamenu .dropdown-menu li {
	padding:0;
}

header.navbar .megamenu .dropdown-menu > li > a:before {
	display:none;
}

header.navbar .megamenu .dropdown-menu li ul {
    padding:0 10px 10px;
}

header.navbar .megamenu .dropdown-menu li ul li {
    padding:5px 10px;
    font-size:16px;
}

header.navbar .megamenu .dropdown-menu li ul li a {
	color:#777777;
}

header.navbar .megamenu .dropdown-menu li ul li.title {
	font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
	color:#555555;
	border-bottom: 1px solid #EEEEEE;
	margin-bottom:5px;
}

header.navbar ul li a .label {
	margin-top:2px;
}

header.navbar .megamenu .dropdown-menu li ul li.title .label {
	font-size:14px;
	margin-top:4px;
	font-weight:600;
}

/* NAVBAR CART */

header.navbar .navbar-nav > li.navbar-cart,
header.navbar .navbar-nav > li.navbar-search {
	width:50px;
	text-align:center;
}

header.navbar .navbar-nav > li.navbar-cart > a.dropdown-toggle:after {
	display:none;
}

header.navbar li.navbar-cart .dropdown-menu {
	width:300px;
	padding:15px;
}

header.navbar li.navbar-cart .dropdown-menu > li {
	margin-bottom:15px;
}

header.navbar li.navbar-cart .dropdown-menu > li:last-child {
	margin-bottom:0;
	border-top:1px solid #EEEEEE;
	padding-top:15px;
}

header.navbar li.navbar-cart .dropdown-menu .row {
	margin:0;
}

header.navbar li.navbar-cart .dropdown-menu .row > div {
	padding:0;
}

header.navbar li.navbar-cart .dropdown-menu > li:last-child .row {
	margin:0 -15px;
}

header.navbar li.navbar-cart .dropdown-menu > li:last-child .row > div {
	padding:0 15px;
}

header.navbar li.navbar-cart .dropdown-menu img {
	padding-right:10px;
}

header.navbar li.navbar-cart .dropdown-menu a.remove {
	position:absolute;
	top:0px;
	right:0px;
	font-size:16px;
	color:#999999;
}

header.navbar li.navbar-cart .dropdown-menu h4 {
	margin-top:0;
}

header.navbar li.navbar-cart .dropdown-menu p {
	margin:0;
	font-size:16px;
	color:#666666;
}

/* NAVBAR SEARCH */

header.navbar .navbar-nav > li.navbar-search > a.dropdown-toggle:after {
	display:none;
}

header.navbar .navbar-nav > li.navbar-search {
	position:static;
}

header.navbar li.navbar-search .dropdown-menu {
	left:15px;
	right:15px;
	margin-top:-19px;
	padding:15px 15px 15px 0;
	box-shadow:none;
	z-index:9999;
}

header.navbar-fixed-top li.navbar-search .dropdown-menu {
	box-shadow:0 1px 5px 0 rgba(0,0,0,0.15);
}

header.navbar li.navbar-search .form-control {
	font-size:32px;
	height:auto;
	border:0;
	font-weight:400;
}

/* HEADER SMALL - OFFSET > 165px */

header.navbar.navbar-small {
	height:60px;
	box-shadow:0 0 2px 0 rgba(0,0,0,0.1);
}

header.navbar.navbar-small .navbar-brand {
	padding:20px 15px;
	font-size:36px;
}

header.navbar.navbar-small .navbar-collapse {
	margin:5px 0;
}

header.navbar.navbar-small .dropdown-menu {
	margin-top:5px;
}

header.navbar.navbar-small .megamenu .dropdown-menu {
	margin-top:-5px;
}

header.navbar.navbar-small li.navbar-search .dropdown-menu {
	margin-top:-5px;
}

/* HEADER - NAVBAR TRANSPARENT */

header.navbar-transparent {
	height:60px;
	border:0;
	transition:background-color 0.3s ease-in;
}

header.navbar-transparent.navbar-offset {
	background-color:rgba(0,0,0,0.8);
}

header.navbar-transparent .navbar-brand {
	padding:20px 15px;
	font-size:36px;
}

header.navbar-transparent .navbar-text {
	color:#FFFFFF;
}

header.navbar-transparent .navbar-collapse {
	margin:5px 0;
}

header.navbar-transparent .navbar-brand span,
header.navbar-transparent .navbar-brand:hover {
	color:#FFFFFF;
}

header.navbar-transparent .navbar-nav > li > a {
	color:#FFFFFF;
	font-weight:600;
}

header.navbar-transparent .navbar-nav > li > a.dropdown-toggle:after {
	color:#FFFFFF;
}

header.navbar-transparent .dropdown-menu {
	margin-top:5px;
}

header.navbar-transparent .megamenu .dropdown-menu,
header.navbar-transparent li.navbar-search .dropdown-menu {
	margin-top:-5px;
}

/* HEADER - NAVBAR VERTICAL */

.vertical-menu #page-wrapper {
	margin-left: 263px;
}

.vertical-menu header.navbar-vertical {
	bottom:0;
    width:263px;
    background-color:#FFFFFF;
	border-right:1px solid #EEEEEE;
	box-shadow:0 0 20px 10px rgba(0,0,0,0.15);
}

.vertical-menu header.navbar-vertical > .container {
	position:static;
	width:auto;
	padding:0;
}

@media (min-width:768px) {
	.vertical-menu .navbar-header,
	.vertical-menu .navbar-brand,
	.vertical-menu .navbar-text,
	.vertical-menu .navbar-nav,
	.vertical-menu .navbar-nav > li {
		float:none !important;
	}
	
	.vertical-menu header.navbar .navbar-brand {
		display:block;
		padding:20px 15px;
		margin-left:0;
	}
	
	.vertical-menu header.navbar .navbar-collapse {
		margin:0;
	}
	
	.vertical-menu .navbar-nav {
		margin-right:0;
	}
	
	.vertical-menu header.navbar .navbar-nav > li > a,
	.vertical-menu header.navbar .navbar-nav > li > a:hover,
	.vertical-menu header.navbar .navbar-nav > li > a:focus {
		border-bottom:1px solid #EEEEEE;
	}
	
	.vertical-menu header.navbar .navbar-nav > li > a span.label {
		position:static;
	}
		
	.vertical-menu header.navbar .dropdown-menu {
		left:263px;
		right:auto;
		top:0;
		margin-top:0;
		box-shadow:1px 1px 5px 0 rgba(0,0,0,0.15);
	}
	
	.vertical-menu header.navbar .navbar-nav > li.megamenu {
		position:relative;
	}
	
	.vertical-menu header.navbar .megamenu .dropdown-menu {
		min-width:800px;
	}
	
	.vertical-menu .vertical-menu-top .navbar-nav {
		margin:7px;
	}
	
	.vertical-menu .vertical-menu-top .navbar-nav > li {
		float:left !important;
		margin-right:7px;
	}
	
	.vertical-menu .vertical-menu-top ul.navbar-nav > li:last-child {
		margin-right:0;
	}
	
	.vertical-menu .vertical-menu-top ul.navbar-nav > li > a {
		background-color:#EEEEEE;
		font-size:14px;
		padding:3px 8px;
		border:0 !important;
	}
	
	.vertical-menu header.navbar .vertical-menu-top .navbar-nav > li > a.dropdown-toggle:after {
		display:none;
	}
	
	.vertical-menu .vertical-menu-top .navbar-nav > li > a img,
	.vertical-menu .vertical-menu-top .dropdown-menu > li > a img {
		margin-right:5px;
		position:relative;
		top:-2px;
	}
	
	.vertical-menu .vertical-menu-top .dropdown-menu > li > a {
		font-size:14px;
		padding:7px 10px;
	}
	
	.vertical-menu header.navbar .vertical-menu-top .navbar-nav > li.dropdown.open > a {
		background-color:#F5F5F5;
	}
	
	.vertical-menu header.navbar .navbar-nav > li.navbar-cart {
		width:auto;
	}
	
	.vertical-menu header.navbar .navbar-collapse .navbar-nav > li.navbar-cart,
	.vertical-menu header.navbar .navbar-collapse .navbar-nav > li.navbar-search {
		display:none;
	}
	
	.vertical-menu header.navbar .vertical-menu-top .dropdown-menu {
		left:0;
		top:100%;
		background-color:#FBFBFB;
	}
	
	.vertical-menu .vertical-menu-bottom {
		padding:20px 15px 0;
	}
	
	.vertical-menu .vertical-menu-bottom .navbar-form {
		margin-bottom:40px;
		padding:0;
	}
	
	.vertical-menu .vertical-menu-bottom .contacts {
		margin-bottom:40px;
	}
	
	.vertical-menu .vertical-menu-bottom .copyright {
		text-align:center;
		color:#AAAAAA;
	}
}

/* HEADER - NAVBAR DARK */

header.navbar-dark {
	background-color:#333333 !important;
	border-color:#2B2B2B !important;
}

header.navbar-dark .navbar-brand span {
	color:#FFFFFF;
}

header.navbar-dark .navbar-brand:hover {
	color:#FFFFFF;
}

header.navbar-dark .navbar-text {
	color:#CCCCCC;
}

.vertical-menu header.navbar-dark .navbar-form .form-control {
	background-color:#333333;
	border-color:#555555;
	color:#CCCCCC;
}

header.navbar-dark .navbar-nav > li > a {
	color:#CCCCCC;
}

.vertical-menu header.navbar-dark .navbar-nav > li > a,
.vertical-menu header.navbar-dark .navbar-nav > li > a:hover,
.vertical-menu header.navbar-dark .navbar-nav > li > a:focus {
	border-color:#2E2E2E;
}

header.navbar-dark .navbar-nav > li > a:hover {
	border-color:#555555;
}

header.navbar-dark .dropdown-menu {
	background-color:#333333;
}

header.navbar-dark .dropdown-menu > li > a {
	color:#CCCCCC;
	border-color:#2E2E2E;
}

header.navbar-dark .dropdown-menu > li > a:hover, 
header.navbar-dark .dropdown-menu > li > a:focus {
	background-color:#444444;
}

header.navbar-dark .megamenu .dropdown-menu li ul li.title {
	color:#CCCCCC;
	border-color:#2E2E2E;
}

header.navbar-dark .megamenu .dropdown-menu li ul li a {
	color:#CCCCCC;
}

header.navbar-dark li.navbar-cart .dropdown-menu p {
	color:#CCCCCC;
}

header.navbar-dark li.navbar-cart .dropdown-menu > li:last-child {
	border-color:#2E2E2E;
}

header.navbar-dark li.navbar-search .form-control {
	background-color:#333333;
}

.vertical-menu header.navbar-dark .vertical-menu-top ul.navbar-nav > li > a {
	background-color:#222222;
}

.vertical-menu header.navbar-dark .vertical-menu-top .navbar-nav > li.dropdown.open > a {
	background-color:#2D2D2D;
}
	
.vertical-menu header.navbar-dark .vertical-menu-top .dropdown-menu {
	background-color:#3B3B3B;
}

.vertical-menu header.navbar-dark .vertical-menu-bottom .copyright {
	color:#999999;
}

/*------------------------------
	CONTENT
------------------------------*/

.content {
	padding:30px 0;
}

.content a {
	transition:all 0.2s ease-in;
}

body.page-boxed .content.pattern {
	background-image:url(../images/pattern.png);
}

.content h1 {
    font-weight:700;
    color:#666666;
	margin-top:0;
	font-size:46px;
}

.content h2 {
    font-weight:900;
    color:#666666;
	letter-spacing:-1px;
	margin-top:0;
	font-size:32px;
}

.content h3,
.separator h3 {
	margin:0 0 10px;
    font-weight: bold;
    color: #777777;
    letter-spacing: -1px;
}

.separator {
	background-color:#FFFFFF;
	padding:10px 0;
}

.default-style,
.products-header,
.account-content,
.sidebar .widget,
article.product-item,
.pagination-wrapper,
.login-form-wrapper,
.error-wrapper,
#scrollspy-nav .affix,
.components-content {
	background-color:#FFFFFF;
	box-shadow:0 1px 2px 0 rgba(0,0,0,0.1);
}

.default-style {
	padding:20px;
}

.border-top {
	border-top:1px solid #F0F0F0;
}

.border-bottom {
	border-bottom:1px solid #F0F0F0;
}

.content .box {
	padding:12px;
	border:2px solid #F0F0F0;
	margin-bottom:30px;
}

.content .box h4 {
	margin:-12px -12px 12px;
	background-color:#F5F5F5;
	border-bottom:2px solid #F0F0F0;
	padding:12px;
	color:#888888;
}

.content .box h5 {
	font-weight:bold;
	color:#888888;
}

.content .box h4 + p,
.content .box h5 + p {
	margin-bottom:0;
}

.content .box ul {
	margin:0;
}

.content .box ul li {
	font-size:15px;
	color:#777777;
	padding:2px 0;
}

.section-title {
	text-align:center;
	margin-bottom:30px;
}

.section-title p {
	color:#AAAAAA;
	font-weight:600;
	font-size:16px;
}

/* SEPARATOR: NEWSLETTER */

.separator-newsletter {
	background-color:#F9F9F9;
	border-top:2px solid rgba(0,0,0,0.05);
	border-bottom:2px solid rgba(0,0,0,0.05);
	margin:30px 0 0;
	padding:20px 0;
}

.separator-newsletter .newsletter-left {
	position:relative;
}

.separator-newsletter .newsletter-right {
	padding-left:210px;
}

.separator-newsletter .newsletter-badge {
	text-align:center;
	border:2px solid rgba(0,0,0,0.1);
	border-radius:50%;
	width:180px;
	height:180px;
	color:#FFFFFF;
	padding-top:26px;
	position:absolute;
	top:-50px;
	box-shadow:1px 1px 6px 1px rgba(0,0,0,0.2);
	z-index:950;
}

.separator-newsletter .newsletter-badge span,
.featured-products .newsletter-badge span {
	display:block;
	line-height:42px;
	text-transform:uppercase;
	font-size:18px;
	font-weight:bold;
}

.separator-newsletter .newsletter-badge span.price,
.featured-products .newsletter-badge span.price {
	font-size:50px;
}

.separator-newsletter p {
	color:#AAAAAA;
	font-size:16px;
	margin:0;
}

.separator-newsletter form {
	margin-top:17px;
}

/*------------------------------
	COMPONENTS
------------------------------*/

/* ICON NAVIGATION */

.icon-nav {
	margin-top:30px;
}

.icon-nav a {
	text-align:center;
	display:block;
	background-color: #F9F9F9;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
	padding:20px 0;
	font-size:20px;
	font-weight:300;
	color:#777777;
	transition:all 0.2s ease-in;
	margin-bottom:30px;
}

.icon-nav a i {
	display:block;
	margin-bottom:8px;
	font-size:28px;
}

.icon-nav a:hover {
	color:#FFFFFF;
}

/* VERTICAL TABS */

.tabs.vertical-tabs .nav-tabs {
    border:0;
}

.tabs.vertical-tabs .nav-tabs > li {
    float:none;
	margin-right:0;
}

.tabs.vertical-tabs .nav-tabs > li > a {
	background-color:#F9F9F9;
	border:1px solid #F0F0F0;
	border-top:0;
}

.tabs.vertical-tabs .nav-tabs > li > a:hover {
	background-color:#F5F5F5;
	border-color:#F0F0F0;
}

.tabs.vertical-tabs .nav-tabs > li:first-child > a {
	border-top:1px solid #F0F0F0;
}

.tabs.vertical-tabs .nav-tabs > li.active > a, 
.tabs.vertical-tabs .nav-tabs > li.active > a:focus, 
.tabs.vertical-tabs .nav-tabs > li.active > a:hover {
	background-color:#FFFFFF;
	border-left:2px solid #E87169;
	border-right:0;
	border-bottom:1px solid transparent;
}

.tabs.vertical-tabs .tab-content {
	padding-top:0;
}

/*------------------------------
	SIDEBAR
------------------------------*/

.sidebar .widget {
	margin-bottom:20px;
}

.sidebar .widget h3 {
	margin:0;
	font-size:16px;
	font-weight:600;
	letter-spacing:0;
}

.sidebar .widget h3 a {
	color:#FFFFFF;
	padding:8px 10px;
	display:block;
}

.sidebar .widget h3 a:after {
	content:"\f0d7";
	float:right;
}

.sidebar .widget h3 a.collapsed:after {
	content:"\f0da";
}

.widget .widget-body {
	padding:13px;
}

/* WIDGET: CATEGORIES */

.widget-categories .widget-body > ul {
	margin-bottom:0;
}

.widget-categories .widget-body > ul > li {
	border:0;
	border-radius:0;
	box-shadow:none;
	margin-bottom:0;
	padding:7px 0;
	font-size:16px;
	font-weight:600;
	background-color:transparent;
}

.widget-categories .widget-body > ul > li > a.collapsed {
	color:#888888;
}

.widget-categories .widget-body > ul > li > a:before {
	content:"\f147";
	margin-right:7px;
	font-size:14px;
}

.widget-categories .widget-body > ul > li > a.collapsed:before {
	content:"\f196";
}

.widget-categories .widget-body > ul > li > a.collapsed:hover {
	color:#666666;
}

.widget-categories .widget-body > ul > li > a span {
	float:right;
	color:#AAAAAA;
	font-weight:300;
	font-size:12px;
	font-family:Tahoma, Geneva, sans-serif;
	margin-top:2px;
}

.widget-categories .widget-body > ul > li > ul {
	margin-left:35px;
}

.widget-categories .widget-body > ul > li > ul > li {
	padding:3px 0;
}

.widget-categories .widget-body > ul > li > ul > li > a {
	font-weight:400;
	font-size:15px;
	color:#888888;
	position:relative;
}

.widget-categories .widget-body > ul > li > ul > li > a:hover {
	color:#444444;
}

.widget-categories .widget-body > ul > li > ul > li > a:before {
	content:"";
	position:absolute;
	width:4px;
	height:4px;
	background-color:#DDDDDD;
	top:50%;
	left:-13px;
	margin-top:-2px;
}

/* WIDGET: PRICE */

.widget-price .widget-body .price-slider {
	margin:5px 0 20px;
}

.widget-price .widget-body .price-slider input {
	background-color:transparent;
	border:0;
	width:50%;
	margin-bottom:5px;
	font-size:18px;
	color:#999999;
}

.widget-price .widget-body .price-slider input.pull-right {
	text-align:right;
}

.widget-price .widget-body .ui-widget-content {
	background:none;
	border:0;
	border-radius:10px;
	background-color:#E0E0E0;
	height:18px;
	clear:both;
}

.widget-price .widget-body .ui-slider .ui-slider-range {
	background:none;
	border-radius:10px;
}

.widget-price .widget-body .ui-slider .ui-slider-handle {
	border-radius:50%;
	background:none;
	border:2px solid #E87169;
	background-color:#FFFFFF;
	top:0;
	width:18px;
	height:18px;
	outline:none;
}

.widget-price .widget-body .ui-slider > div + span {
	margin-left:0;
}

.widget-price .widget-body .ui-slider > div + span + span {
	margin-left:-16px;
}

/* WIDGET: COLOR */

.widget-color .widget-body {  
	text-align:center;
}

.widget-color .widget-body .checkbox {  
    display:inline-block; 
	width:30px;  
    height:30px;
	margin:3px 2px;
}

.widget-color .widget-body .checkbox label {
	min-height:0;
}

.widget-color .widget-body .checkbox label:before {
    width:30px;  
    height:30px;  
	top:0;
	border-radius:50%;
	opacity:0.5;
	border:4px solid rgba(0,0,0,0);
}

.widget-color .widget-body .checkbox.blue label:before {background-color:#3d78d8;}
.widget-color .widget-body .checkbox.red label:before {background-color:#e84c3d;}
.widget-color .widget-body .checkbox.green label:before {background-color:#0d8609;}
.widget-color .widget-body .checkbox.dark-gray label:before {background-color:#4e5b5e;}
.widget-color .widget-body .checkbox.dark-cyan label:before {background-color:#008686;}
.widget-color .widget-body .checkbox.orange label:before {background-color:#ffa500;}
.widget-color .widget-body .checkbox.pink label:before {background-color:#db7093;}
.widget-color .widget-body .checkbox.purple label:before {background-color:#954db3;}
.widget-color .widget-body .checkbox.brown label:before {background-color:#7a5230;}
.widget-color .widget-body .checkbox.yellow label:before {background-color:#d3b61c;}

.widget-color .widget-body .checkbox > input[type=checkbox]:checked + label:before {  
	opacity:1;
	font-size:19px;
}

.widget-color .widget-body .checkbox .tooltip.top {
	white-space:nowrap;
	margin-top:-17px;
}

/* WIDGET: CHECKBOX */

.widget-checkbox .widget-body .checkbox {
	margin:8px 0;
}

.widget-checkbox .widget-body .checkbox label {  
	padding-right:35px;
} 

.widget-checkbox .widget-body .checkbox span {
	color:#AAAAAA;
	font-weight:300;
	font-size:12px;
	font-family:Tahoma, Geneva, sans-serif;
	position:absolute;
	top:2px;
	right:0;
}

/*------------------------------
	SIDEBAR - BLOG
------------------------------*/

.blog .sidebar .widget {
	background-color:transparent;
	box-shadow:none;
	margin-bottom:30px;
}

.blog .sidebar .widget h3 {
	background-color:transparent;
	margin:0 0 10px;
    font-size:20px;
    font-weight:bold;
    color:#777777;
    letter-spacing:-1px;
}

.blog .widget .widget-body {
	padding:0;
}

/* WIDGET: POST CATEGORIES */

.widget-post-categories ul li {
	border-bottom:1px dashed #EBEBEB;
	padding:8px 0;
	font-size:16px;
}

.widget-post-categories ul li:last-child {
	border-bottom:0;
}

.widget-post-categories ul li a {
	color:#777777;
	position:relative;
	padding-left:17px;
}

.widget-post-categories ul li a:before {
    content:"";
    position:absolute;
    width:4px;
    height:4px;
    background-color:#DDDDDD;
    top:50%;
    left:5px;
    margin-top:-2px;
}

.widget-post-categories ul li a .badge {
	float:right;
}

/* WIDGET: RECENT COMMENTS */

.widget-recent-comments ul li {
	padding:5px 0 15px 30px;
	position:relative;
}

.widget-recent-comments ul li:before {
	content:"\f075";
	position:absolute;
	color:#DDDDDD;
	left:0;
	top:7px;
	font-size:20px;
}

.widget-recent-comments ul li p {
	margin:0;
	color:#AAAAAA;
	font-size:12px;
}

.widget-recent-comments ul li p.text {
	margin-bottom:10px;
	color:#777777;
	font-size:14px;
	font-weight:600;
}

/*------------------------------
	PRODUCTS
------------------------------*/

.products-header {
	margin-bottom:20px;
	padding:10px;
}

.products-header > .row > div:last-child {
	text-align:right;
}

.products-header .toggle-list-grid button {
	margin:0 4px;
}

.products-header .order-by {
	display:inline-block;
	margin-left:10px;
}

/* GRID/LIST */

article.product-item  {
	position:relative;
	overflow:hidden;
	margin-bottom:30px;
}

article.product-item .product-overlay {
	position:relative;
	display:block;
}

article.product-item .product-overlay .product-mask {
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
	opacity:0;
	filter:alpha(opacity=0);
	transition:all 0.2s ease-out;
	background-color:#999999;
	z-index:110;
}

article.product-item:hover .product-overlay .product-mask {
	opacity:0.3;
	filter:alpha(opacity=30);
}

article.product-item .product-image-2 {
	position:absolute;
	top:0;
	opacity:0;
	transition:opacity 0.6s ease-in;
	z-index:100;
}

article.product-item:hover .product-image-2 {
	opacity:1;
}

article.product-item .product-quickview {
	position:absolute;
	width:100%;
	top:50%;
	left:0;
	margin-top:-32px;
    transition:all 0.2s ease-out;
	text-align:center;
	opacity:0;
	z-index:130;
}

article.product-item:hover .product-quickview {
	opacity:1;
}

article.product-item .product-quickview .btn-quickview {
    background-color:rgba(255,255,255,0.8);
	padding:20px 0;
	width:100%;
	border-radius:0;
	font-size:20px;
	text-transform:uppercase;
	font-weight:600;
	transition:all 0.3s ease-in;
}

article.product-item .product-quickview .btn-quickview:hover, 
article.product-item .product-quickview .btn-quickview:focus {
	color:#666666;
}

article.product-item .product-permalink {
	display:block;
    width:100%;
    height:100%;
	position:absolute;
	z-index:120;
}

article.product-item h3 {
	font-weight:600;
	font-size:18px;
	line-height:24px;
	margin-top:15px;
	letter-spacing:0;
	height:44px;
}

article.product-item .product-rating {
	padding:0 0 5px;
}

article.product-item .product-rating i,
.account-content .product-rating i,
.small-product li .product-rating i {
	font-size:16px;
	padding:0 1px;
	color:#F2CD00;
}

article.product-item .price,
.small-product li .price {
	font-size:18px;
	color:#888888;
}

article.product-item .price ins,
.small-product li .price ins {
	text-decoration:none;
	font-weight:600;
	color:#333333;
}

article.product-item .price del + ins,
.small-product li .price del + ins {
	text-decoration:none;
	font-size:22px;
	margin-left:3px;
	font-weight:400;
}

article.product-item .buttons {
	padding:15px 0;
}

article.product-item .buttons a.add-to-cart i {
	margin-right:5px;
}

article.product-item .buttons-simple {
	padding:10px 0 15px;
}

article.product-item .buttons-simple a {
	font-size:19px;
	padding:0 7px;
}

article.product-item .buttons-simple a:hover {
	color:#666666;
}

article.product-item .product-labels {
	position:absolute;
	padding:15px;
	z-index:125;
}

article.product-item .product-labels .label {
	border-radius:50%;
	width:36px;
	height:36px;
	padding:10px 0 0;
	display:inline-block;
	font-size:12px;
	border:2px solid #FFFFFF;
	text-transform:uppercase;
}

article.product-item .product-labels .label.label-danger {
	background-color:#E87169;
}

article.product-item .product-labels .label.label-info {
	background-color:#39B3CA;
}

/* LIST */

#products.list > div {
	width:100%;
}

#products.list article.product-item .product-body {
	padding-right:15px;
}

#products.list article.product-item p {
	font-size:15px;
	color:#888888;
	margin:10px 0 0;
}

#products.list article.product-item h3 {
	height:auto;
}

#products.list article.product-item .product-labels {
	top:0;
	right:15px;
}

#products.list article.product-item .product-labels .label {
	box-shadow:0 0 1px 0 rgba(0,0,0,0.2);
}

/* GRID */

#products.grid article.product-item {
	text-align:center;
}

#products.grid article.product-item > .row > div {
	width:100%;
	float:none;
	position:static;
}

#products.grid article.product-item .product-body {
	padding:0 10px;
}

#products.grid article.product-item p {
	display:none;
}

#products.grid article.product-item .product-labels {
	top:0;
	left:0;
}

/*------------------------------
	SINGLE PRODUCT
------------------------------*/

article.product-item.product-single {
	padding:0 15px 0 0;
}

article.product-item.product-single .product-labels {
	top:0;
    right:0;
}

article.product-item.product-single .product-labels .label {
    box-shadow:0 0 1px 0 rgba(0,0,0,0.2);
}

article.product-item.product-single h3 {
    height:auto;
}

article.product-item.product-single .product-rating {
	padding:0 0 10px;
}

article.product-item.product-single .product-info {
	margin:20px 0;
}

article.product-item.product-single .product-info li {
	padding:3px 0;
	font-weight:bold;
}

article.product-item.product-single .product-info li span {
	width:100px;
	color:#B0B0B0;
	display:inline-block;
	font-weight:400;
}

article.product-item.product-single p {
	font-size:15px;
	color:#888888;
	margin:10px 0 20px;
}

article.product-item.product-single .product-form label {
    margin:0 7px 0 0;
}

article.product-item.product-single .product-form .form-control {
	height:38px;
}

article.product-item.product-single .product-quantity,
article.product-item.product-single .product-size,
article.product-item.product-single .product-color {
	padding-bottom:10px;
}

article.product-item.product-single .product-quantity .btn {
	font-size:16px;
	font-weight:bold;
	width:40px;
	float:left;
	background-color:#F0F0F0;
	z-index:500;
	padding:6px 12px;
}

article.product-item.product-single .product-quantity .form-control {
	display:inline-block;
	width:44px;
	text-align:center;
	height:38px;
	float:left;
	margin-left:-2px;
	z-index:510;
}

article.product-item.product-single .product-quantity .btn:last-child {
	border-left:0;
}

article.product-item.product-single .add-to-cart {
	float:right;
	padding:7px 12px;
}

article.product-item.product-single .add-to-cart i {
	margin-right:5px;
}

article.product-item.product-single .product-links {
	clear:both;
	margin-top:10px;
	padding-top:10px;
	border-top:2px solid #F0F0F0;
}

article.product-item.product-single .product-links li {
	padding-right:10px;
}

article.product-item.product-single .product-links a {
	color:#999999;
}

article.product-item.product-single .product-links a:hover {
	color:#666666;
}

article.product-item.product-single .product-links a i {
	margin-right:4px;
}

.tabs.product-tabs .nav-tabs {
	border-bottom:0;
}

.tabs.product-tabs .nav-tabs > li > a {
	background-color:#FFFFFF;
	opacity:0.85;
	border-top:2px solid transparent;
}

.tabs.product-tabs .nav-tabs > li.active > a, 
.tabs.product-tabs .nav-tabs > li.active > a:focus, 
.tabs.product-tabs .nav-tabs > li.active > a:hover {
	background-color:#FFFFFF;
	color:#999999;
	border-top:2px solid #E87169;
	opacity:1;
}

.tabs.product-tabs .tab-content {
	background-color:#FFFFFF;
	padding:20px;
}

.releated-products {
	margin-top:40px;
}

/*------------------------------
	REVIEWS
------------------------------*/

.comments .media .media-left {
	padding-right:20px;
}

.comments .media .media-body h3 {
	margin:0 0 10px;
	color:#777777;
	font-size:20px;
	font-weight:bold;
	display:inline-block;
}

.comments .media .media-body {
	position:relative;
}

.comments .media .media-body .meta {
	float:right;
	font-weight:bold;
	margin-top:5px;
}

.comments > .media > .media-body > .meta {
	transform:translateX(53px);
	transition:transform 0.25s ease-in-out;
}

.comments > .media:hover > .media-body > .meta {
	transform:translateX(0);
}

.comments .media .media-body .meta .date {
	color:#BCC7D1;
	font-size:14px;
}

.comments .media .media-body .meta a {
	margin-left:15px;
	cursor:pointer;
}

.comment-form h3,
.modal-add-review .modal-title {
	font-weight:bold;
	color:#777777;
	margin-top:0;
	letter-spacing:-1px;
	font-size:24px;
}

.comment-form .comment-notes {
	color:#AAAAAA;
}

.comment-form textarea.form-control {
	height:130px;
}

.comment-form .btn i {
	margin-right:5px;
}

/*------------------------------
	FOOTER
------------------------------*/

footer.navbar {
	margin:0;
	border:0;
	background-color:#272727;
	padding:30px 0 20px;
	color:#616161;
	border-radius:0;
}

footer.navbar a:hover {
	color:#AAAAAA;
}

.footer-widget h4 {
	color:#B8B8B8;
	font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
}

.footer-widget ul li  {
	padding:5px 0;
}

.footer-widget a {
	color:#616161;
	font-size:16px;
}

/* WIDGET CONTACTS */

.footer-widget-contacts ul li {
	font-size:16px;
	font-weight:bold;
	padding:7px 0;
}

.footer-widget-contacts ul li i {
	color:#B8B8B8;
    border:2px solid #B8B8B8;
    border-radius:50%;
    padding:5px 0 0;
    text-align:center;
    width:31px;
    height:31px;
    margin-right:5px;
    font-size:16px;
}

.footer-widget-contacts ul li.social {
	padding-top:20px;
}

.footer-widget-contacts ul li.social a {
	padding-right:3px;
}

.footer-widget-contacts ul li.social i {
	color:#787878;
	border-color:#787878;
	margin-bottom:10px;
}

.footer-widget-contacts ul li.social a:hover i {
	color:#B8B8B8;
	border-color:#B8B8B8;
}

/* WIDGET TWITTER */

.footer-widget #twitter-wrapper p {
	margin-bottom:10px;
	font-size:13px;
}

.footer-widget #twitter-wrapper a {
	font-size:13px;
	color:#888888;
}

.footer-widget #twitter-wrapper a:hover {
	color:#AAAAAA;
}

.footer-widget #twitter-wrapper .timePosted {
	display:none;
}

/* WIDGET FACEBOOK */

.footer-widget-facebook ul li a {
	display:block;
	margin:0 5px;
}

.footer-widget-facebook p {
	font-weight:600;
	font-size:18px;
	margin-top:20px;
}

.footer-widget-facebook p a {
	font-size:12px;
	color:#CCCCCC;
	float:right;
	position:relative;
	top:-4px;
	letter-spacing:1px;
}

.footer-widget-facebook p a i {
	margin-right:5px;
}

/* FOOTER COPYRIGHT */

.footer-bottom {
	border-top:2px solid #373737;
	margin-top:20px;
	padding-top:20px;
}

.footer-bottom p.copyright {
	margin:0;
	font-weight:600;
	font-size:15px;
}

.footer-bottom ul.payment-methods {
	margin:0;
	text-align:right;
	font-size:28px;
}

.footer-bottom ul.payment-methods li {
	padding:0 2px;
	transition:all 0.2s ease-in;
}

.footer-bottom ul.payment-methods li:hover {
	color:#AAAAAA;
}

/*------------------------------
	MY ACCOUNT
------------------------------*/

.sidebar .nav-stacked > li {
	padding:0;
}

.sidebar .nav-stacked > li + li {
	margin-top:0;
}

.sidebar .nav-pills > li > a {
	border-radius:0;
	font-weight:600;
	color:#999999;
	border-bottom:2px solid #EBEBEB;
	font-size:14px;
}

.sidebar .nav-pills > li:last-child > a {
	border-bottom:0;
}

.sidebar .nav-pills > li > a:after {
    content:"\f0da";
    float:right;
	margin-top:3px;
}

.sidebar .nav-pills > li > a:hover,
.sidebar .nav-pills > li > a:focus {
	background-color:#F3F3F3;
}

.sidebar .nav-pills > li.active > a {
	color:#FFFFFF;
}

/* ACCOUNT CONTENT */

.account-content {
	padding:20px;
}

.account-content h3 {
    font-weight:600;
}

.account-content h3 span {
	font-weight:300;
}

.account-content p {
    font-size:15px;
    color:#777777;
}

.account-content b {
    font-size:15px;
    color:#777777;
}

.account-content h4 {
	margin:0 0 10px;
    font-size:18px;
    color:#666666;
	font-weight:600;
}

/* PROFILE */

.account-content .account-profile-top div {
	display:inline-block;
}

.account-content .account-profile-top div.button {
	float:right;
}

.account-content .account-profile-top img {
	margin-right:10px;
}

.account-content .account-profile-top h3 {
	position:relative;
	top:12px;
}

.account-content .account-profile-top h3 small {
	display:block;
	padding:5px 0 0 2px;
	color:#BBBBBB;
	font-size:60%;
}

.account-content .account-profile-top a {
	margin-top:19px;
}

.account-content .form-horizontal {
	clear:both;
	margin-top:20px;
}

/* ADDRESS */

.account-content .table td a.btn i {
	width:11px;
}

.account-content .table td a.btn {
	margin-right:5px;
}

.account-content .table td a.btn:last-child {
	margin-right:0;
}

/* SINGLE ORDER */

.account-content .order-status {
	margin:4px 0 14px;
	display:inline-block;
}

.account-content .order-action {
	float:right;
}

.account-content .order-action li {
	padding:0;
}

.account-content .products-order {
	border:2px solid #F0F0F0;
	padding:15px;
}

.account-content ul.order-total {
	text-align:right;
	margin:0;
}

.account-content ul.order-total li {
	padding:2px 0;
	font-size:16px;
}

.account-content ul.order-total span {
	width:100px;
	display:inline-block;
	font-weight:bold;
	color:#555555;
}

.account-content ul.order-total span.total {
	color:#E74C3C;
}

/* SHOPPING CART */

.account-content .shopping-cart {
	margin-bottom:20px;
}

.account-content .shopping-cart .update-cart {
	float:right;
}

/* CHECKOUT */

.account-content .checkout-step {
	position:relative;
	text-align:center;
	margin-bottom:15px;
}

.account-content .checkout-step .number {
	background-color:#F0F0F0;
	color:#AAAAAA;
	border-radius:50%;
	display:inline-block;
	height:29px;
	width:29px;
	font-weight:bold;
	font-size:20px;
}

.account-content .checkout-step.active .number {
	color:#FFFFFF;
}

.account-content .checkout-step .title {
	font-weight:600;
	font-size:16px;
	color:#888888;
	margin:7px 0 0;
}

.account-content .checkout-step.active .title {
	color:#555555;
}

.account-content .checkout-step:after {
	position:absolute;
	height:16px;
	width:16px;
	margin-left:-8px;
	left:50%;
	bottom:-24px;
	content:"";
	border-radius:50%;
	border:2px solid #F0F0F0;
	background-color:#FFFFFF;
	z-index:1;
}

.account-content .checkout-progress {
	position:relative;
	left:12.5%;
	width:75%;
	border-radius:0;
	background-color:#F0F0F0;
	box-shadow:none;
	height:2px;
	margin-bottom:40px;
}

.account-content .checkout-progress .progress-bar {
	box-shadow:none;
}

.account-content .products-order.checkout {
	margin-bottom:20px;
}

.checkout .addresses-toggle {
	margin-bottom:20px;
}

.billing-information textarea {
	height:130px;
}

.shipping-information .checkbox {
	margin-bottom:30px;
}

#shipping-address {
	display:none;
}

.account-content .products-order.shipping-method .radio,
.account-content .products-order.payment-method .radio {
    margin:15px 0;
	border:0;
	box-shadow:none;
}

.account-content .products-order.shipping-method .radio:first-child,
.account-content .products-order.payment-method .radio:first-child {
    margin-top:0;
}

.account-content .products-order.shipping-method .radio:last-child,
.account-content .products-order.payment-method .radio:last-child {
    margin-bottom:0;
}

.account-content .products-order.shipping-method .radio .panel-collapse,
.account-content .products-order.payment-method .radio .panel-collapse {
    margin-left:30px;
}

.account-content .products-order .radio label span {
    margin-left:25px;
	font-weight:400;
	color:#AAAAAA;
}

.account-content .products-order.shipping-method .radio .panel-collapse .radio {
     margin:7px 0;
}

.account-content .products-order.shipping-method .shipping-method-description {
    margin:10px 0 20px 30px;
}

.account-content .products-order.shipping-method .shipping-method-description p {
    margin:0 0 10px;
}

.account-content .products-order.shipping-method .shipping-method-description ul {
    margin:0;
}

.account-content .products-order.shipping-method .shipping-method-description ul li {
	font-weight:bold;   
}

.account-content .products-order.shipping-method .shipping-method-description ul li span {
	font-weight:400;
    width:70px;
	display:inline-block;
}

.account-content .products-order.payment-method .credit-card-form {
	padding-top:15px;
}

.account-content .products-order.payment-method .credit-card-form label {
	font-weight:700;
	padding-left:0;
}

#credit-card-img {
	padding:4px 8px;
	color:#999999;
}

#credit-card-img i {
	font-size:24px;
	width:35px;
}

#credit-card-img img {
	height:24px;
}

.expiration-date .form-control {
	width:49%;
	display:inline-block;
}

#credit-card-error p {
	margin:0;
	color:#E74C3C;
	font-weight:bold;
    font-size:12px;
}

/* TABLE PRODUCTS */

.table-products {
	border-bottom:2px solid #EBEBEB;
	margin-bottom:10px;
}

.table-products > thead > tr > th, 
.table-products > tbody > tr > th {
	padding:10px 5px;
    font-size:16px;
    border-right:2px solid #FFFFFF;
	border-bottom:0;
    text-align:center;
}

.table-products > thead > tr > th:last-child, 
.table-products > tbody > tr > th:last-child {
    border-right:0;
}

.table-products > thead > tr > td, 
.table-products > tbody > tr > td {
	padding:10px 0;
}

.table-products > tbody > tr:first-child > td {
	border-width:0;
}

.table-products .col-xs-1 img {
	max-width:70px;
}

.table-products h4 {
	margin-left:25px;
}

.table-products h4 small {
	margin-top:5px;
    display:block;
    color:#AAAAAA;
    font-style:italic;
}

.table-products span {
    color:#888888;
    font-size:16px;
}

.table-products .form-control {
    text-align:center;
    font-size:16px;
    margin:15px auto;
    width:60%;
}

/* COMPARE */

.account-content.compare {
	padding:0;
}

#table-compare,
.dragtable-sortable table {
	margin:0;
}

.dragtable-sortable li {
	background-color:#FFFFFF;
}

.dragtable-sortable .ui-sortable-placeholder { 
    background-color:#DDDDDD;
	box-shadow:0 0 15px 5px rgba(0,0,0,0.2) inset;
}

#table-compare tr td,
.dragtable-sortable li > table > tbody > tr > td {
	text-align:center;
	font-size:16px;
}

#table-compare tr th,
.dragtable-sortable li > table > thead > tr > th {
	text-align:center;
	background-color:transparent;
}

#table-compare tr th i.btn,
.dragtable-sortable tr th i.btn {
	padding:6px 0;
	width:33px;
	font-weight:400;
}

#table-compare tr th i.fa-arrows,
.dragtable-sortable tr th i.fa-arrows {
	cursor:move;
	margin-right:3px;
}

#table-compare tr td.title,
.dragtable-sortable tr td.title {
	width:15%;
	text-align:right;
	color:#666666;
	font-weight:bold;
	border-right:2px solid #EBEBEB;
	background-color:#F5F5F5;
	padding:8px 12px;
}

#table-compare tr td img,
.dragtable-sortable tr td img {
	height:150px;
	margin-bottom:10px;
}

#table-compare tr td img + a,
.dragtable-sortable tr td img + a{
	font-size:16px;
}

/* SIGN UP / SIGN IN */

.login-form-wrapper {
    padding:20px;
	margin:40px 0 10px;
}

.login-form-wrapper form {
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:2px solid #D0D0D0;
}

.login-form-wrapper.no-border form {
	margin-bottom:0;
	padding-bottom:0;
	border-bottom:0
}

.login-form-wrapper h3 {
	text-align:center;
    font-size:32px;
}

.login-form-wrapper p {
	margin:0 0 10px;
    font-weight:600;
    color:#999;
}

.btn-brand {
	background-color:#777777;
	display:block;
	color:#FFFFFF;
	opacity:0.9;
	transition:opacity 0.3s ease-in;
	padding:10px 12px;
}

.btn-brand:hover {
	opacity:1;
	color:#FFFFFF;
}

.btn-brand i {
	margin-right:5px;
}

.btn-facebook {background-color:#3B5998;}
.btn-google-plus {background-color:#D13E29;}

.form-text {
	font-weight:600;
	text-align:center;
	color:#888888;
	font-size:15px;
}

/*------------------------------
	ERROR
------------------------------*/

.error-wrapper {
	text-align:center;
    padding: 20px;
	margin:40px 0 20px;
}

.error-wrapper .error-number {
	font-size:120px;
	line-height:1;
	color:#888888;
}

.error-wrapper .error-text h1 {
	font-size:36px;
	margin:20px 0;
	font-weight:bold;
}

.error-wrapper .error-text p {
	font-size:18px;
	color:#888888;
}	

/*------------------------------
	JUMBOTRONS - CAROUSELS
------------------------------*/

.jumbotron {
	background-color:transparent;
	margin:0;
	padding:0;
}

.jumbotron-full-height {
	height:100%;
}

.container .jumbotron-small {
	padding:0;
}

.jumbotron .owl-theme .owl-controls .owl-nav div {
	width:50px;
	height:50px;
	padding:4px 0 0;
	font-size:28px;
	background-color:rgba(0,0,0,0.25);
	margin-top:-25px;
}

.jumbotron .owl-carousel .item {
	position:relative;
	background-size:cover;
	height:400px;
}

.jumbotron-full-height .owl-carousel,
.jumbotron-full-height .owl-carousel .item,
.jumbotron-full-height .owl-carousel .owl-item,
.jumbotron-full-height .owl-carousel .owl-stage,
.jumbotron-full-height .owl-carousel .owl-stage-outer {
	height:100%;
}

.jumbotron .owl-carousel .slide-mask {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0);
}

.jumbotron .owl-carousel .slide-body {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	padding:0 70px;
}

.jumbotron h1 {
	color:#FFFFFF;
	font-size:70px;
	font-family:Raleway, sans-serif;
	font-weight:800;
	margin:0 0 20px;
}

.jumbotron h2 {
	color:#FFFFFF;
	font-size:40px;
	font-family:Raleway, sans-serif;
	font-weight:200;
	margin:0 0 20px;
}

.jumbotron ul li {
	color:#FFFFFF;
	font-size:24px;
	padding:8px 0;
	font-family:Raleway, sans-serif;
	font-weight:300;
}

.jumbotron p {
	color:#FFFFFF;
	font-size:20px;
	font-family:Raleway, sans-serif;
	font-weight:500;
	letter-spacing:4px;
}

.jumbotron a.btn {
	font-family:Raleway, sans-serif;
	font-weight:400;
	margin-right:5px;
	border-color:#FFFFFF;
}

.jumbotron a.btn:last-child {
	margin-right:0;
}

.jumbotron a.btn-lg {
	font-size:18px;
	padding:12px 20px;
}

.jumbotron a.btn-default {
	color:#FFFFFF;
}

.jumbotron a.btn-inverse {
	color:#666666;
	background-color:#FFFFFF;
}

.jumbotron a.btn-inverse:hover {
	background-color:rgba(255,255,255,0.8);
}

.jumbotron .border {
	border:2px solid #FFFFFF;
	padding:10px 15px;
	display:inline-block;
}

.jumbotron .grey-background {
	background-color:rgba(0,0,0,0.35);
	display:inline-block;
	padding:10px 15px;
}

.jumbotron .color-background {
	display:inline-block;
	padding:10px 15px;
}

.jumbotron-video .mb_YTPBar {
	display:none !important;
}

/* HOMEPAGE 1 */

#homepage-1-carousel .item {
	height:550px;
}

/* HOME1 - SLIDE 1 */

#homepage-1-carousel .slide-1 {
	text-align:center;
	background-image:url(../images/slide-1.jpg);
}

#homepage-1-carousel .slide-1 .slide-mask {
	background-color:rgba(0,0,0,0.2);
}

#homepage-1-carousel .slide-1 i {
	color:#FFFFFF;
	font-size:60px;
	margin:170px 0 10px;
}

/* HOME1 - SLIDE 2 */

#homepage-1-carousel .slide-2 {
	background-image:url(../images/slide-2.jpg);
}

#homepage-1-carousel .slide-2 .slide-mask {
	background-color:rgba(0,0,0,0.35);
}

#homepage-1-carousel .slide-2 h1 {
	font-size:36px;
	font-weight:200;
	margin:130px 0 20px;
}

/* HOME1 - SLIDE 3 */

#homepage-1-carousel .slide-3 {
	text-align:right;
	background-image:url(../images/slide-3.jpg);
}

#homepage-1-carousel .slide-3 .slide-mask {
	background-color:rgba(0,0,0,0.35);
}

#homepage-1-carousel .slide-3 h1 {
	margin:210px 0 20px;
}

/* HOMEPAGE 2 */

/* HOME2 - SLIDE 1 */

#homepage-2-carousel .slide-1 {
	background-image:url(../images/slide-4.jpg);
	text-align:center;
}

#homepage-2-carousel .slide-1 .slide-mask {
	background-color:rgba(0,0,0,0.35);
}

#homepage-2-carousel .slide-1 h1 {
	font-weight:300;
	font-size:40px;
	margin:300px 0 0;
}

#homepage-2-carousel .slide-1 h2 {
	font-size:70px;
	line-height:90px;
	margin:50px 0;
}

/* HOME2 - SLIDE 2 */

#homepage-2-carousel .slide-2 {
	background-image:url(../images/slide-5.jpg);
}

#homepage-2-carousel .slide-2 .slide-mask {
	background-color:rgba(0,0,0,0.35);
}

#homepage-2-carousel .slide-2 h1 {
	font-weight:300;
	font-size:50px;
	margin:250px 0 0;
}

#homepage-2-carousel .slide-2 h2 {
	font-weight:300;
	font-size:32px;
	margin:0 0 30px;
}

#homepage-2-carousel .slide-2 ul li {
	font-size:32px;
}

#homepage-2-carousel .slide-2 ul li i {
	margin-right:10px;
	color:#DDDDDD;	
}

/* HOMEPAGE 3 */

/* HOME3 - SLIDE 1 */

#homepage-3-carousel .slide-1 {
	text-align:center;
	background-image:url(../images/slide-7.jpg);
}

#homepage-3-carousel .slide-1 h1 {
	margin:110px 0 30px;
}

#homepage-3-carousel .slide-2 h1 {
	margin:150px 0 0;
	font-weight:500;
}

/* HOME3 - SLIDE 2 */

#homepage-3-carousel .slide-2 {
	background-image:url(../images/slide-6.jpg);
	background-position:0 -200px;
}

/* HOMEPAGE 4 */

#homepage-4-carousel {
	margin-bottom:30px;
}

#homepage-4-carousel .item {
	height:300px;
}

/* HOME4 - SLIDE 1 */

#homepage-4-carousel .slide-1 {
	background-image:url(../images/slide-6.jpg);
}

#homepage-4-carousel .slide-1 .slide-mask {
	background-color:rgba(255,255,255,0.8);
}

#homepage-4-carousel .slide-1 h1 {
	color:#555555;
	font-size:50px;
	margin:100px 0 0;
}

/* HOME4 - SLIDE 2 */

#homepage-4-carousel .slide-2 {
	background-image:url(../images/slide-7.jpg);
}

#homepage-4-carousel .slide-2 h1 {
	margin:70px 0 10px;
}

#homepage-4-carousel .slide-2 h2 {
	font-weight:800;	
}

/* HOMEPAGE 6 */

#homepage-6-carousel .item {
	height:550px;
}

/* HOME6 - SLIDE 1 */

#homepage-6-carousel .slide-1 {
	text-align:center;
}

#homepage-6-carousel .slide-1 .slide-mask {
	background-color:rgba(0,0,0,0.2);
}

#homepage-6-carousel .slide-1 i {
	color:#FFFFFF;
	font-size:60px;
	margin:170px 0 10px;
}

/* OWL ANIMATION */

.owl-animation .animation {
	transition:transform 1s ease-in;
	opacity:0;
}

.owl-animation .fast {
	transition-duration:0.5s;
}

.owl-animation .slow {
	transition-duration:1.5s;
}

.owl-animation .very-slow {
	transition-duration:2s;
}

.owl-animation .active .animation {
	opacity:1;
}

.owl-animation .delay-1,
.owl-animation .delay-2,
.owl-animation .delay-3,
.owl-animation .delay-4,
.owl-animation .delay-5 {
	transition-delay:0s;
}

.owl-animation .active .delay-1 {
	transition-delay:0.5s;
}

.owl-animation .active .delay-2 {
	transition-delay:1s;
}

.owl-animation .active .delay-3 {
	transition-delay:1.5s;
}

.owl-animation .active .delay-4 {
	transition-delay:2s;
}

.owl-animation .active .delay-5 {
	transition-delay:2.5s;
}

.owl-animation .top-to-bottom {transform:translateY(-1000px);}
.owl-animation .bottom-to-top {transform:translateY(1000px);}

.owl-animation .left-to-right {transform:translateX(-1000px);}
.owl-animation .right-to-left {transform:translateX(1000px);}

.owl-animation .active .top-to-bottom,
.owl-animation .active .bottom-to-top {
	transform:translateY(0);
}

.owl-animation .active .left-to-right,
.owl-animation .active .right-to-left {
	transform:translateX(0);
}

/*------------------------------
	HOMEPAGE SECTIONS
------------------------------*/

/* SECTION - NOTIFICATION */

.notification {
    padding:40px 0 50px;
	text-align:center;
}

.notification span {
	font-size:36px;
    line-height:46px;
    font-weight:800;
    color:#7C7C7C;
	display:block;
	margin-bottom:20px;
}

.notification a {
	color:#888888;
	border-color:#999999;
	font-size:22px;
	padding:12px 60px;
}

.notification h3 {
    font-size: 34px;
    font-family: Raleway, sans-serif;
    font-weight: 400;
    letter-spacing: 2px;
	color:#888888;
	margin:10px 0 20px;
}

.notification p {
    font-size:21px;
	line-height:33px;
	font-weight:100;
	color:#999999;
	margin:0;
}

/* SECTION - CATEGORIES */

.categories {
	padding:0;
}

.categories .category {
	position:relative;
}

.categories .category .category-mask {
	position:absolute;
	top:0;
	left:0;
	background-color:#000000;
	width:80%;
	height:80%;
	opacity:0.2;
	margin-top:8%;
	margin-left:10%;
	transition:all 0.3s ease-in;
}

.categories .category:hover .category-mask {
	opacity:0.4;
}

.categories .category .category-title {
	position:absolute;
	top:50%;
	left:0;
	color:#FFFFFF;
	text-align:center;
	width:100%;
	font-size:56px;
	text-transform:uppercase;
	letter-spacing:3px;
	font-weight:900;
	margin-top:-41px;
	transition:all 0.3s ease-in;
}

.categories .category .category-title span {
	font-size:20px;
	display:block;
}

/* SECTION - CATEGORIES 2 */

.categories-2 {
	background-color:#FFFFFF;
	padding:60px 0 30px;
}

.categories-2 .category {
	background-color:#F7F7F7;
	margin-bottom:30px;
	transition:all 0.3s ease-in;
}

.categories-2 .category:hover {
	background-color:#F0F0F0;
}

.categories-2 .category .category-body {
	padding:50px 25px 50px 0;
}

.categories-2 .category .category-body.left {
	padding:50px 0 50px 25px;
}

.categories-2 .category img {
	max-height:182px;
}

.categories-2 .category h3 {
	font-weight:700;
	font-size:24px;
	margin-bottom:20px;
}

.categories-2 .category h3 span {
	color:#CCCCCC;
	font-size:18px;
	text-transform:uppercase;
}

/* SECTION - GRID PRODUCTS */

.grid-products {
	background-color:#FFFFFF;
}

.grid-products .product-item {
	text-align:center;
	box-shadow:none;
}

.grid-products .product-item h3 a {
	color:#777777;
}

/* SECTION - TOP PRODUCTS */

.featured-products {
	background-color:#FFFFFF;
}

.featured-products .tabs p {
	font-size:17px;
	color:#888888;
}

.featured-products .product-item {
	text-align:center;
	box-shadow:none;
}

.featured-products .product-item h3 {
	height:auto;
}

.featured-products .newsletter-badge {
	padding-top:27px;
}

.featured-products .newsletter-badge span {
	text-align:center;
	color:#888888;
	font-size:20px;
}

/* SECTION - TESTIMONIALS */

.testimonials {
	background-color:#FFFFFF;
	text-align:center;
	padding:60px 0;
}

.testimonials .item {
	margin:0 100px;
}

.testimonials .item img {
	width:120px;
	margin-bottom:20px;
}

.testimonials .item p {
	font-style:italic;
	font-size:18px;
	line-height:30px;
}

.testimonials .item h3 {
	font-weight:bold;
	font-style:italic;
	font-size:18px;
	line-height:30px;
	letter-spacing:0;
}

/* SECTION - SERVICES */

.services {
	background-color:#FFFFFF;
	padding:60px 0 0;
}

.services .service {
	text-align:center;
	padding:15px;
	border-right:1px solid #F0F0F0;
	margin-bottom:60px;
}

.services .row > div:nth-child(3n) .service {
	border-right-width:0;
}

.services .service i {
	color:#CCCCCC;
	font-size:26px;
	border-radius:50%;
	border:3px solid #CCCCCC;
	width:50px;
	height:50px;
	padding-top:10px;
}

.services .service h3 {
	color:#666666;
	margin:15px 0 10px;
	font-size:20px;
	transition:all 0.3s ease-in;
}

.services .service p {
	color:#999999;
    font-size:14px;
	line-height:24px;
    font-family:Raleway, sans-serif;
    font-weight:500;
	margin:0;
}

/* SECTION - BRANDS */

.brands {
	padding:40px 0;
}

.brands .item a:before {
	content:"";
	width:80px;
	height:3px;
	position:absolute;
	left:50%;
	margin-left:-40px;
	bottom:0;
	opacity:0;
	transition:opacity 0.3s ease-in;
}

.brands .item a:hover:before {
	opacity:1;
}

/* SECTION - RECENT BLOG POST */

.recent-blog-posts {
	background-color:#FFFFFF;
	padding:60px 0 30px;
}

.recent-blog-posts .post {
	margin-bottom:30px;
}

.recent-blog-posts .post h3 {
	font-size:15px;
	font-weight:600;
	letter-spacing:0;
	font-family: Raleway, sans-serif;
	margin:10px 0;
}

.recent-blog-posts .post h3 a {
	color:#222222;
}

.recent-blog-posts .post span.date {
	display:inline-block;
	font-weight:600;
	border:2px solid #E87169;
	padding:0 5px;
}

/* SECTION - OUR STORES */

.our-stores {
	background-image:url(../images/map.jpg);
	background-size:cover;
	background-repeat:no-repeat;
	background-position:0 -100px;
	padding:60px 0;
	position:relative;
}

.our-stores h2 {
	color:#FFFFFF;
	margin-bottom:30px;
	font-size:48px;
}

.our-stores h2 a {
	color:#FFFFFF;
	float:right;
	font-size:22px;
	padding:12px 18px;
}

.our-stores .store {
	background-color:rgba(0,0,0,0.5);
	padding:30px;
	margin-bottom:30px;
}

.our-stores .store h3 {
	font-weight:600;
}

.our-stores .store ul li {
	color:#DDDDDD;
	font-size:16px;
	padding:5px 0;
}

/* SECTION - SMALL PRODUCTS */

.small-products {
	background-color:#F5F5F5;
	padding-bottom:0;
}

.small-products h2 {
	font-weight:700;
	font-size:24px;
	margin-bottom:20px;
}

.small-products .small-product {
	margin-bottom:30px;
}

.small-product li {
	padding:0 0 15px;
	margin-bottom:15px;
	position:relative;
	border-bottom:1px dashed #DDDDDD;
	clear:both;
}

.small-product li:last-child {
	border-bottom:0;
	margin-bottom:0;
	padding-bottom:0;
}

.small-product li img {
	float:left;
	width:60px;
	margin-right:15px;
}

.small-product li h3 {
	font-size:18px;
	font-weight:600;
	transition:all 0.2s ease-in;
}

/*------------------------------
	BLOG
------------------------------*/

.blog {
	background-color:#FFFFFF;
	border-top:1px solid #F0F0F0;
}

.blog article.post {
	padding-left:130px;
	position:relative;
	margin-bottom:40px;
	padding-bottom:40px;
	border-bottom:1px solid #F0F0F0;
}

.blog article.post h3 a {
	color:#666666;
}

.blog article.post .post-left {
	position:absolute;
	width:100px;
	left:0;
	top:0;
	padding:0 10px;
}

.blog article.post .post-left .author img {
	margin-bottom:10px;
}

.blog article.post .post-left .author span {
	display:block;
	text-align:center;
	font-weight:600;
	color:#888888;
}

.blog article.post .post-left .author span.name {
	display:block;
	font-size:16px;
	font-weight:900;
}

.blog article.post .meta div {
	margin-right:5px;
}

.blog article.post .meta .date,
.blog article.post .meta .category {
	display:inline-block;
	font-weight:600;
	border:2px solid #E87169;
	padding:0 5px;
	margin-bottom:10px;
}

.blog article.post .meta .category {
	color:#888888;
	border-color:#888888;
}

.blog article.post .attachment {
	margin:10px 0 20px;
}

.blog article.post p {
	font-size:16px;
	line-height:26px;
	color:#444444;
}

.blog article.post .post-bottom .tags {
	float:left;
	margin-top:8px;
}

.blog article.post .post-bottom .btn {
	float:right;
}

.blog .pagination {
	margin-bottom:10px;
	float:right;
}

.post > img {
	margin-bottom:20px;
}

.post h4 {
	font-weight:700;
	font-size:21px;
	color:#444444;
}

.post h5 {
	font-weight:700;
	font-size:18px;
	color:#444444;
}

.blog .comments {
	margin:20px 0;
}

.blog .comment-form {
	background-color:#F0F0F0;
	padding:30px;
}

.blog .comment-form textarea {
	height:160px;
}

/*------------------------------
	LOOKBOOK
------------------------------*/

.lookbook {
	background-color:#FFFFFF;
}

.lookbook h2 {
	font-family: Raleway, sans-serif;
	text-align:center;
	font-size:60px;
	margin:0 0 20px;
	font-weight:500;
}

.lookbook p {
	font-family: Raleway, sans-serif;
	text-align:center;
	font-size:30px;
	line-height:40px;
	font-weight:200;
	color:#888888;
}

.lookbook-services {
	background-color:#FFFFFF;
	padding-bottom:0;
}

.lookbook-services h3 {
	font-size:50px;
	font-weight:500;
	margin-top:100px;
}

.lookbook-services p {
	color:#888888;
	font-size:20px;
	line-height:30px;
}

.lookbook-services img {
	max-width:350px;
}

/*------------------------------
	OTHER PAGES
------------------------------*/

.eshop {
	font-size:16px;
	color:#777777;
}

.eshop ul li {
	padding:5px 0;
}


.eshop ul li i {
	margin-right:10px;
}

.eshop .services {
	padding:20px 0 0;
}

.eshop .services h2 {
	margin-bottom:30px;
}

#scrollspy-nav .affix {
	top:80px;
	width:263px;
}

/* FAQ */

.faq .tabs {
	margin-top:30px;
}

/* STORES */

.map-wrapper {
	position:relative;
}

#map-tooltip {
	position:absolute;
	top:30px;
	left:30px;
	color:#FFFFFF;
	font-size:18px;
	font-weight:bold;
}

#map-tooltip p {
	margin:0;
	padding:15px 20px;
	border:2px solid rgba(0,0,0,0.1);
}

.stores form {
	float:right;
}

.stores form label {
	font-size:18px;
	position:relative;
	top:2px;
}

.stores select.form-control {
	width:200px;
	font-size:16px;
	height:40px;
}

.store.default-style {
	padding:0;
	margin-bottom:30px;
}

.store .store-body {
	padding:20px;
	position:relative;
}

.store .store-body h3 {
	font-size:20px;
	line-height:26px;
	font-weight:600;
	letter-spacing:0;
}

.store .store-body ul {
	margin:0;
}

.store .store-body p,
.store .store-body ul li {
	font-size:14px;
}

.store .store-body ul li i {
    border:2px solid #E87169;
    border-radius:50%;
    padding-top:4px;
    text-align:center;
    width:27px;
    height:27px;
    margin-right:5px;
}

.store .store-body ul.grey-color li i {
    color:#999999;
	border-color:#999999;
}

.store .store-image {
	position:relative;
}

.store .store-opening-hours {
	position:absolute;
	top:30px;
	right:30px;
	padding:20px;
}

.store .store-opening-hours ul {
    margin:0;
}

.store .store-opening-hours ul li {
    font-weight:bold;
	color:#FFFFFF;
	font-size:18px;
	padding:2px 0;
}

.store .store-opening-hours ul li span {
    font-weight:400;
    width:100px;
    display:inline-block;
}

/* DOWNLOADS */

.downloads .table > tbody > tr:first-child > td {
	border-top:0;
}

.downloads .table h3 {
	font-size:18px;
	margin:0;
}

.downloads .table p {
	margin:0;
	color:#BABABA;
}

.downloads .table p.text-right {
	font-weight:400;
}

/*------------------------------
	COMPONENTS
------------------------------*/

.components-content {
	padding:20px;
}

.components-content h2.component-heading {
	border-bottom:2px solid #D0D0D0;
	padding-bottom:10px;
	margin-bottom:30px;
	margin-top:50px;
}

.components-content h2.component-heading:first-child {
	margin-top:0;
}

.components-content h2.component-heading i {
	color:#B0B0B0;
	font-size:24px;
	margin-right:15px;
}

.components h1,
.components h2,
.components h3,
.components h4,
.components h5,
.components h6 {
	margin-bottom:30px;
}

.components .table {
	margin-bottom:50px;
}

.components .default-style {
	background-color:#F9F9F9;
	margin-bottom:30px;
}

#default-carousel .item,
#default-carousel-fade .item {
	height:300px;
	background-size:cover;
}

/*------------------------------
	RESPONSIVITY
------------------------------*/

@media (min-width:1200px) and (max-width:1400px) {
	body.page-boxed .jumbotron .owl-theme .owl-controls .owl-nav div {
		display:none!important;
	}
}

@media (min-width:992px) and (max-width:1100px) {
	body.page-boxed .jumbotron .owl-theme .owl-controls .owl-nav div {
		display:none!important;
	}
}

@media (min-width:768px) and (max-width:880px) {
	body.page-boxed .jumbotron .owl-theme .owl-controls .owl-nav div {
		display:none!important;
	}
}

@media (min-width:1367px) and (max-width:1600px) {
	#homepage-2-carousel .slide-1 h1 {
		margin-top:200px;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		margin-top:200px;
	}
}

@media (min-width:1200px) and (max-width:1366px) {
	#homepage-2-carousel .slide-1 h1 {
		margin-top:160px;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		margin-top:160px;
	}
}

/*------------------------------
	MEDIUM DEVICES
------------------------------*/

@media (min-width:992px) and (max-width:1199px) {	
	.jumbotron .owl-carousel .slide-body {
		padding:0 60px;
	}
	
	.jumbotron .owl-theme .owl-controls .owl-nav div {
		width:40px;
		height:40px;
		padding:3px 0 0;
		font-size:24px;
		margin-top:-20px;
	}
	
	#homepage-1-carousel .slide-1 {
		background-position-y:-100px;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		margin-top:130px;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:60px;
		line-height:80px;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		margin-top:150px;
	}
	
	#homepage-3-carousel .slide-1 h1 {
		font-size:64px;
	}
	
	#homepage-4-carousel .slide-1 h1 {
		margin-top:70px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-prev {
		left:-50px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-next {
		right:-50px;
	}
	
	.separator-newsletter {
		margin:20px 0 0;
	}
	
	.separator-newsletter .newsletter-badge {
		top:-39px;
	}
	
	.footer-widget #twitter-wrapper a {
		font-size:12px;
	}
}

/*------------------------------
	SMALL DEVICES
------------------------------*/

@media (min-width:768px) and (max-width:991px) {
	header.navbar-default .navbar-nav > li > a {
		font-size:16px;
		padding:15px 10px;
	}
	
	body.page-boxed #homepage-1-carousel .item {
		height:496px;
	}
		
	.jumbotron .owl-theme .owl-controls .owl-nav div {
		width:40px;
		height:40px;
		padding:3px 0 0;
		font-size:24px;
		margin-top:-20px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-prev {
		left:-50px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-next {
		right:-50px;
	}
	
	.content h3, .separator h3 {
		font-size:20px;
	}
	
	.breadcrumb-wrapper p {
		font-size:30px;
	}
	
	.product-body .btn {
		font-size:11px;
		padding:3px 6px;
	}
	
	#products.grid article.product-item .product-body {
		padding:0 5px;
	}
	
	article.product-item h3 {
		font-size:16px;
		line-height:22px;
	}
	
	.product-body .btn.add-to-cart i {
		display:none;
	}
	
	.input-group-lg > .form-control {
		font-size:14px;
		height:36px;
    	padding:6px 12px;
	}
	
	.input-group-lg > .input-group-btn > .btn,
	.btn-lg {
		font-size:14px;
		padding:6px 12px;
		line-height:1.42857143;
		height:auto;
	}
	
	.separator-newsletter {
		margin:10px 0 0;
	}
	
	.separator-newsletter .newsletter-badge {
		width:160px;
		height:160px;
		padding-top:22px;
		top:-32px;
	}
	
	.separator-newsletter .newsletter-badge span {
		font-size:16px;
		line-height:38px;
	}
	
	.separator-newsletter .newsletter-badge span.price {
		font-size:40px;
	}
	
	.separator-newsletter p {
		font-size:14px;
		color:#999999;
	}
	
	.footer-widget h4 {
		font-size:18px;
	}
	
	.footer-widget a {
		font-size:14px;
	}
	
	.footer-widget-contacts ul li {
		font-size:14px;
	}
	
	.footer-widget-contacts ul li i {
		display:none;
	}
	
	.footer-widget-contacts ul li.social i {
		display:inline-block;
	}
	
	.footer-widget #twitter-wrapper p {
		font-size:12px;
	}
	
	.footer-widget #twitter-wrapper a {
		font-size:11px;
	}
	
	.footer-widget-facebook p {
		font-size:14px;
	}
	
	.footer-bottom ul.payment-methods {
		font-size:24px;
		margin-top:6px;
	}
	
	.account-content .checkout-step .title {
		height:44px;
	}
	
	.stores select.form-control {
		width:160px;
	}
	
	.store .store-opening-hours ul li {
		font-size:16px;
	}
	
	.store .store-opening-hours {
		padding:15px;
		top:20px;
		right:20px;
	}
	
	.store .store-body h3 {
		font-size:18px;
	}
	
	.blog article.post .post-left {
		width:80px;
	}
	
	.blog article.post .post-left .author span.name {
		font-size:14px;
	}
	
	.blog article.post {
		padding-left:100px;
	}
	
	.categories .category .category-title {
		font-size:40px;
		margin-top:-31px;
	}
	
	.categories .category .category-title span {
		font-size:18px;
	}
	
	.testimonials .item {
		margin:0;
	}
	
	.our-stores {
		background-position:0;
		padding:30px 0;
	}
	
	.our-stores .store {
		padding:15px;
	}
	
	.sidebar .nav-pills > li > a {
		padding:8px 10px;
	}
	
	.sidebar .nav-pills > li > a:after {
		display:none;
	}
	
	.account-content .form-horizontal .control-label {
		padding-top:0;
		margin-bottom:5px;
	}
	
	.small-product li h3 {
		font-size:16px;
		line-height:20px;
	}
	
	.notification h3 {
		font-size:24px;
		margin-bottom:10px;
	}
	
	.notification p {
		font-size:18px;
	}
	
	.notification span {
		font-size:28px;
		line-height:36px;
		margin-bottom:10px;
	}
	
	.grid-products .small-product li img {
		display:none;
	}
	
	#map-canvas {
		height:400px;
	}
	
	.jumbotron h1 {
		font-size:60px;
	}
	
	.jumbotron p {
		font-size:18px;
	}
	
	#homepage-1-carousel .slide-1 {
		background-position-y:0;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		margin-top:100px;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:50px;
		line-height:70px;
		margin:30px 0;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		margin-top:120px;
		font-size:40px;
	}
	
	#homepage-2-carousel .slide-2 h2 {
		font-size:26px;
	}
	
	#homepage-2-carousel .slide-2 ul li {
		font-size:24px;
	}
	
	#homepage-3-carousel .slide-1 h1 {
		font-size:46px;
	}
	
	#homepage-3-carousel .slide-1 h2 {
		font-size:30px;
	}
	
	#homepage-3-carousel .slide-2 {
		background-position:0;
	}
	
	#homepage-3-carousel .slide-2 h1 {
		font-size:46px;
	}
	
	#homepage-4-carousel .slide-1 h1 {
		font-size:46px;
	}
}

/*------------------------------
	EXTRA SMALL DEVICES
------------------------------*/

@media (max-width:767px) {
	.jumbotron .owl-carousel .item {
		height:300px!important;
	}
	
	.vertical-menu .jumbotron .owl-carousel .item {
		height:100%!important;
	}
	
	.jumbotron-full-height {
		height:auto;
	}
	
	.vertical-menu .jumbotron-full-height {
		height:100%;
	}
	
	.jumbotron .owl-carousel .slide-body {
		padding:0 15px;
	}
	
	.jumbotron .owl-theme .owl-controls,
	.nav-inside .owl-controls {
		display:none;
	}
	
	.jumbotron h1 {
		font-size:50px;
	}
	
	.jumbotron p {
		font-size:16px;
	}
	
	.jumbotron ul li {
		font-size:20px;
		padding:4px 0;
	}
	
	#homepage-1-carousel .slide-1 {
		background-position-y:0;
	}
		
	#homepage-1-carousel .slide-1 i {
		margin-top:60px;
		font-size:50px;
	}
	
	#homepage-1-carousel .slide-2 h1 {
		margin-top:60px;
		font-size:30px;
	}
	
	#homepage-1-carousel .slide-3 h1 {
		margin-top:100px;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		font-size:24px;
		margin:70px 0 0;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:34px;
		line-height:44px;
		margin:25px 0;
	}
	
	#homepage-2-carousel .slide-1 .btn{
		font-size:14px;
    	padding:6px 12px;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		font-size:26px;
		margin:70px 0 0;
	}
	
	#homepage-2-carousel .slide-2 h2 {
		font-size:20px;
		margin:0 0 15px;
	}
	
	#homepage-2-carousel .slide-2 ul li {
		font-size:16px;
	}
	
	#homepage-3-carousel .slide-1 h1 {
		margin-top:75px;
	}
		
	#homepage-3-carousel .slide-2 {
		background-position:0;
	}
	
	#homepage-3-carousel .slide-2 h1 {
		margin-top:120px;
	}
		
	header.navbar {
		height:60px;
	}
	
	header.navbar .navbar-brand {
		padding:20px 15px;
		font-size:36px;
	}
	
	header.navbar .navbar-toggle {
		background-color:transparent;
		border:0;
		font-size:26px;
		margin:3px 15px 0 0;
	}
	
	.navbar .navbar-toggle:focus, 
	.navbar .navbar-toggle:hover {
		background-color:transparent;
		color:#777777;
	}
	
	header.navbar .navbar-collapse {
		border:0;
		border-bottom:2px solid #E87169;
		box-shadow:none;
		background-color:#FFFFFF;
		margin:0 -15px;
		max-height:300px;
	}
	
	.vertical-menu header.navbar .navbar-collapse {
		margin:0;
	}
	
	header.navbar-dark .navbar-collapse {
		background-color:#333333;
	}
	
	header.navbar-transparent .navbar-nav > li > a {
		color:#777777;
		font-weight:400;
	}
	
	header.navbar-transparent .navbar-nav > li > a.dropdown-toggle:after {
		color:#CCCCCC;
	}
	
	header.navbar .navbar-nav > li > a {
		font-weight:bold;
		padding:10px 15px;
	}
	
	header.navbar .navbar-nav > li > a span.label {
		position:static;
		top:0;
		right:0;
	}
	
	header.navbar .dropdown-menu {
		display:block;
		position:static;
		float:none;
		width:auto;
		margin-top:0;
		background-color:transparent;
		box-shadow:none;
	}
	
	header.navbar .open .dropdown-menu > li > a {
		padding:10px 15px;
		line-height:1.42857143;
	}
	
	header.navbar .megamenu .dropdown-menu {
		margin-top:0;	
	}
	
	header.navbar .megamenu .dropdown-menu li ul {
		padding:0 20px 10px;
	}
	
	/* HEADER - NAVBAR VERTICAL */

	.vertical-menu #page-wrapper {
		margin-left:0;
	}
	
	.vertical-menu header.navbar-vertical {
		width:100%;
	}
	
	.sidebar.right {
		margin-top:20px;
	}
	
	.content h2 {
		font-size:26px;
	}
	
	.breadcrumb-wrapper h2 {
		font-size:18px;
	}
	
	.breadcrumb-wrapper p {
		font-size:26px;
	}
	
	.products-header > .row > div:last-child {
		text-align:left;
	}
	
	.products-header .order-by {
		display:block;
		margin-left:0;
	}
	
	article.product-item .product-quickview {
		display:none;
	}
	
	.separator-newsletter .newsletter-right {
		padding-left:190px;
	}
	
	.separator-newsletter .newsletter-badge {
		width:160px;
		height:160px;
		top:-12px;
		padding-top:23px;
	}
	
	.separator-newsletter .newsletter-badge span {
		font-size:16px;
		line-height:38px;
	}
	
	.separator-newsletter .newsletter-badge span.price {
		font-size:44px;
	}
	
	.input-group-lg > .form-control {
		font-size:14px;
		height:36px;
    	padding:6px 12px;
	}
	
	.input-group-lg > .input-group-btn > .btn,
	.btn-lg {
		font-size:14px;
		padding:6px 12px;
		line-height:1.42857143;
		height:auto;
	}
	
	footer.navbar {
		text-align:center;
	}
	
	.footer-widget {
		margin-bottom:20px;
	}
	
	.footer-widget-facebook p a {
		float:none;
		position:static;
		margin-left:10px;
	}
	
	.footer-bottom ul.payment-methods {
		text-align:center;
		margin-top:10px;
	}
	
	.table-responsive {
		border:0;
	}
	
	.table-responsive.border {
		border:2px solid #EBEBEB;
	}
	
	.table-products .form-control {
		width:100%;
	}
	
	.account-content .products-order {
		border:0;
		padding:0;
	}
	
	.account-content .shopping-cart {
		margin-bottom:0;
	}
	
	.account-content .shopping-cart > a {
		margin-bottom:15px;
	}
	
	.box .input-group {
		margin-bottom:20px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-prev {
		left:30px;
	}
	
	body.page-boxed .owl-theme.nav-inside .owl-controls .owl-nav div.owl-next {
		right:30px;
	}
	
	.product-form .form-group {
		margin:0;
	}
	
	.account-content .checkout-step:after {
		display:none;
	}
	
	#table-compare tr td {
		font-size:14px;
	}
	
	.stores form {
		float:none;
	}
	
	.stores form label {
		position:static;
	}
	
	.services .row > div:nth-child(3n) .service {
		border-right-width:1px;
	}
	
	.services .row > div:nth-child(2n) .service {
		border-right-width:0;
	}
	
	.services.services-3x .service {
		border-right:0;
		margin-bottom:15px;
    	padding-bottom:30px;
	}
	
	.services.services-3x .row > div:first-child .service {
		border-bottom:1px solid #F0F0F0;
	}
	
	.tabs.vertical-tabs .nav-tabs {
		margin-bottom:15px;
	}
	
	.blog article.post .post-left {
		display:none;
	}
	
	.blog article.post {
		padding-left:0;
		margin-bottom:20px;
    	padding-bottom:20px;
	}
	
	.blog .pagination {
		float:none;
	}
	
	.post > img {
		padding-left:0!important;
		padding-right:0!important;
	}
	
	.small-products h2 {
		font-size:20px;
		margin-bottom:10px;
	}
	
	.categories .category .category-title {
		font-size:40px;
		margin-top:-31px;
	}
	
	.categories .category .category-title span {
		font-size:18px;
	}
	
	.testimonials {
		padding:30px 0;
	}
	
	.testimonials .item {
		margin:0;
	}
	
	.recent-blog-posts {
		text-align:center;
		padding:30px 0 0;
	}
	
	.services {
		padding-top:30px;
	}
	
	.our-stores {
		background-position:0;
		padding:30px 0;
	}
	
	.our-stores h2 a {
		float:none;
		display:block;
		margin-top:10px;
	}
	
	.lookbook h2 {
		font-size:40px;
	}
	
	.lookbook p {
		font-size:24px;
    	line-height:34px;
	}
	
	.lookbook-services h3 {
		margin-top:20px;
		text-align:center;
		font-size:26px;
	}
		
	.lookbook-services p {
		text-align:center;
		font-size:16px;
    	line-height:26px;
	}
	
	.lookbook-services img {
		max-width:200px;
	}
	
	.featured-products .newsletter-body {
		text-align:center;
	}
	
	#featuredproducts-1 img,
	#featuredproducts-2 img {
		margin-top:20px;
	}
	
	.notification h3 {
		margin-top:30px;
	}
	
	.grid-products article.product-item h3 {
		height:auto;
	}
	
	#homepage-4-carousel .item {
		height:auto;
	}
		
	.modal .modal-header .close {
		margin-top:0;
		margin-right:0;
	}
	
	#modalAdvertising .modal-body {
		padding:0 30px 15px;
	}
	
	#map-tooltip {
		display:none;
	}
	
	#map-canvas {
		height:280px;
	}
	
	.login-form-wrapper {
		margin:10px 0 10px;
	}
	
	.form-text {
		margin:0;
	}
}

@media (max-width:600px) {
	.table-products > tbody > tr > td:first-child,
	.table-products > thead > tr > th:first-child {
		display:none;
	}
	
	.table-products > thead > tr > td, 
	.table-products > tbody > tr > td {
		padding:10px;
	}
	
	.table-products h4 {
		margin-left:0;
	}
	
	.table-products > thead > tr > th, 
	.table-products > tbody > tr > th {
		font-size:14px;
	}
	
	.account-content .shopping-cart {
		padding-bottom:5px;
	}
		
	.account-content .shopping-cart .update-cart {
		float:none;
	}
	
	.categories .category .category-title {
		font-size:28px;
		margin-top:-22px;
	}
	
	.categories .category .category-title span {
		font-size:14px;
	}
}

@media (max-width:568px) {
	footer.navbar .row > div,
	.breadcrumb-wrapper .row > div,
	.product-item.product-single > .row > div,
	.checkout-steps > .row > div,
	.services > .row > div,
	.services > .container > .row > div,
	.categories > .row > div,
	.order-meta > .row > div,
	.login-form-wrapper > .row > div,
	.lookbook-services > .container > .row > div {
		width:100%;
		float:none;
	}	
	
	.alert {
		padding:10px;
	}
	
	.alert-dismissable, 
	.alert-dismissible {
		padding-right:30px;
	}
	
	.breadcrumb {
		text-align:left;
		padding:10px 0;
	}
	
	.pagination > li > a {
		margin:0 3px 3px;
		width:34px;
    	height:34px;
		font-size:14px;
		padding-top:5px;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		margin:80px 0 0;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:28px;
		line-height:40px;
		margin:20px 0;
	}
	
	#homepage-3-carousel .slide-1 h1 {
		font-size:40px;
		margin-top:40px;
	}
	
	#homepage-3-carousel .slide-2 h1 {
		margin-top:60px;
	}
	
	.product-body .btn.add-to-cart i {
		display:none;
	}
	
	article.product-item.product-single {
		padding-right:0;
	}

	article.product-item.product-single .product-body {
		padding:0 15px;
	}
	
	article.product-item.product-single .product-labels {
		right:15px;
	}
	
	.comments .media img {
		width:40px;
	}
	
	.comments .media .media-body h3 {
		font-size:16px;
	}
	
	.tabs .nav-tabs > li > a {
		font-size:14px;
		padding:10px 15px;
	}
	
	.tabs.product-tabs .tab-content {
		padding:10px;
	}
	
	.tabs .tab-content p, 
	.tabs .tab-content ul li {
		font-size:14px;
	}
	
	.services .service {
		border-right:0;
		border-bottom:1px solid #F0F0F0;
		margin-bottom:15px;
		padding-bottom:30px;
	}
	
	.services .row > div:last-child .service {
		border-bottom:0;
		margin-bottom:0;
	}
	
	.blog .comment-form {
		padding:15px;
	}
	
	.categories .category .category-title {
		font-size:56px;
		margin-top:-54px;
	}
	
	.categories .category .category-title span {
		font-size:20px;
	}
	
	.account-content .account-profile-top img {
		width:50px;
	}
	
	.account-content .account-profile-top h3 {
		font-size:20px;
	}
	
	.account-content .account-profile-top h3 small {
		font-size:70%;
	}
	
	.account-content .order-action {
		float:none;
		margin-left:0;
		margin-bottom:7px;
	}
	
	.account-content .order-action li {
		margin-bottom:3px;
	}
	
	.login-form-wrapper > .row > div a.btn {
		margin-bottom:5px;
	}
	
	.login-form-wrapper > .row > div:last-child a.btn {
		margin-bottom:0;
	}
	
	.lookbook-services.image-left h3 {
		margin-top:30px;
	}
	
	.featured-products .product-item h3 {
		font-size:16px;
		line-height:22px;
	}
}

@media (max-width:480px) {
	#products > div,
	.recent-blog-posts > .container > .row > div,
	.categories-2 .category > .row > div  {
		width:100%;
		float:none;
	}
	
	.jumbotron h1 {
		font-size:40px;
	}
	
	.jumbotron p {
		font-size:14px;
		letter-spacing:1px;
	}
	
	.jumbotron ul li {
		font-size:16px;
	}
		
	#homepage-1-carousel .slide-1 i {
		margin-top:50px;
		font-size:40px;
	}	
	
	#homepage-1-carousel .slide-2 h1 {
		font-size:24px;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		font-size:20px;
		margin-top:70px;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:27px;
		line-height:37px;
		margin:15px 0;
	}
	
	#homepage-2-carousel .slide-2 h1 {
		font-size:20px;
	}
	
	#homepage-4-carousel .slide-1 h1 {
		font-size:40px;
		margin-top:90px;
	}
	
	.separator-newsletter .newsletter-badge {
		position:static;
		margin:0 auto 15px;
	}
	
	.separator-newsletter .newsletter-right {
		padding:0;
		text-align:center;
	}
	
	.product-body .btn.add-to-cart i {
		display:inline-block;
	}
	
	.tabs .nav-tabs > li > a {
		font-size:14px;
		padding:8px 12px;
	}
	
	.comments .media .media-left {
		padding-right:10px;
	}
	
	.comments .media .media-body h3 {
		margin-bottom:0;
	}
	
	.comments .media .media-body .meta {
		float:none;
		margin-bottom:10px;
		margin-top:0;
	}
	
	.comments > .media > .media-body > .meta {
		transform:none;
	}
	
	.panel .panel-heading h4.panel-title {
		font-size:16px;
	}
	
	.our-stores .store {
		padding:15px;
	}
	
	.account-content .account-profile-top div.button {
		float:none;
		display:block;
	}
	
	.notification h3 {
		font-size:24px;
		margin-bottom:10px;
	}
	
	.notification p {
		font-size:18px;
	}
	
	.notification span {
		font-size:28px;
		line-height:36px;
		margin-bottom:10px;
	}
	
	.notification a {
		font-size:18px;
		padding:10px 30px;
	}
	
	.categories-2 .category .category-body,
	.categories-2 .category .category-body.left {
		text-align:center;
		padding:20px 0;
	}
}

@media (max-width:360px) {
	.breadcrumb-wrapper p {
		font-size:22px;
	}
	
	.content h2 {
		font-size:22px;
	}
	
	.content .box {
		padding:10px;
	}
	
	.content .box h4 {
		margin:-10px -10px 10px;
		padding:10px;
		font-size:16px;
	}
	
	.tabs .nav-tabs {
		margin-bottom:10px;
	}
	
	.tabs .nav-tabs > li {
		float:none;
		margin-right:0;
	}
	
	.stores select.form-control {
		width:100%;
	}
	
	.store .store-opening-hours {
		padding:12px;
		top:15px;
		right:15px;
	}
	
	.categories .category .category-title {
		font-size:40px;
		margin-top:-31px;
	}
	
	.categories .category .category-title span {
		font-size:18px;
	}
	
	.icon-nav a {
		font-size:16px;
	}
	
	#homepage-2-carousel .slide-1 h1 {
		font-size:18px;
	}
	
	#homepage-2-carousel .slide-1 h2 {
		font-size:24px;
		line-height:32px;
		margin:13px 0;
	}
	
	#homepage-2-carousel .slide-1 a.btn {
		font-size:12px;
		display:block;
		margin:0 0 10px;
	}
}

@media print {	
	#color-switcher,
	#scroll-top,
	.top-header,
	header.navbar,
	.breadcrumb-wrapper,
	.sidebar,
	.alert,
	.account-content .order-action,
	.separator,
	footer.navbar {
		display:none !important;
	}
	
	body,
	.content,
	.account-content {
		padding:0 !important;
	}
	
	.col-sm-6 {
		width:50% !important;
		float:left !important;
	}
	
	a:link:after, a:visited:after {
		content:"" !important;
	}
}